export const tableConfig = Object.freeze({
  organization: {
    filterFunction: "getOrganizationByName",
    fetchDataFunction: "getOrganizationByLoggedUser",
    searchOptions: [
      {
        text: "ID",
        value: 'id',
      },
      {
        text: "NAME",
        value: 'name',
      },
    ],
  },
  servers: {
    filterFunction: "getServerByName",
    fetchDataFunction: "getAllServers",
    sortOptions:[
      {
        text: 'ID',
        value: 'id',
      },
      { 
        text: 'IDENTIFIER',
        value: 'identifier',
      },
      { 
        text: 'NAME',
        value: 'name',
      },
      { 
        text: 'TYPE',
        value: 'enum_type',
      },
      {
        text: "STATUS",
        value: 'enum_status_id'
      },
      { 
        text: 'PUBLIC',
        value: 'is_public',
      },
      { 
        text: 'CREATED AT',
        value: 'created_at',
      }
    ],
    searchOptions: [
      {
        text: "ID",
        value: 'id',
      },
      {
        text: "IDENTIFIER",
        value: 'identifier',
      },
    ],
  },
  orgUsers:{
    filterFunction: "getUsersByOrganization",
    fetchDataFunction: "getUsersByOrganization",
    pillCell: 'status',
    iconCell: 'public',
    searchOptions: [
      {
        value: false,
        text: "ID",
      },
      {
        value: true,
        text: "IDENTIFIER",
      },
    ],
  },
  orgPlans:{
    filterFunction: "getPlansByOrganization",
    fetchDataFunction: "getPlansByOrganization",
    pillCell: 'status',
    iconCell: 'public',
    searchOptions: [
      {
        value: false,
        text: "ID",
      },
      {
        value: true,
        text: "IDENTIFIER",
      },
    ],
  },
  orgServers:{
    filterFunction: "getServersByOrganization",
    fetchDataFunction: "getServersByOrganization",
    pillCell: 'status',
    iconCell: 'public',
    searchOptions: [
      {
        value: false,
        text: "ID",
      },
      {
        value: true,
        text: "IDENTIFIER",
      },
    ],
  },
  plans: {
    filterFunction: "getOrganizationByName",
    fetchDataFunction: "getAllPlans",
    sortOptions:[
      {
        text: 'ID',
        value: 'id',
      },
      { 
        text: 'NAME',
        value: 'name',
      },
      { 
        text: 'STATUS',
        value: 'active',
      },
      { 
        text: 'PRIVATE',
        value: 'is_private',
      },
      {
        text: "CATEGORY",
        value: 'enum_plan_category'
      },
      { 
        text: 'UPDATED AT',
        value: 'updated_at',
      },
      { 
        text: 'CREATED AT',
        value: 'created_at',
      }
    ],
    searchOptions: [
      {
        text: "ID",
        value: 'id',
      },
      {
        text: "NAME",
        value: 'name',
      },
    ],
  },
  planConfig: {
    filterFunction: "getOrganizationByName",
    fetchDataFunction: "getConfigsByPlanId",
    sortOptions:[
      {
        text: 'ID',
        value: 'id',
      },
      { 
        text: 'NAME',
        value: 'name',
      },
      { 
        text: 'STATUS',
        value: 'active',
      },
      { 
        text: 'PRIVATE',
        value: 'is_private',
      },
      {
        text: "CATEGORY",
        value: 'enum_plan_category'
      },
      { 
        text: 'UPDATED AT',
        value: 'updated_at',
      },
      { 
        text: 'CREATED AT',
        value: 'created_at',
      }
    ],
    searchOptions: [
      {
        text: "ID",
        value: 'id',
      },
      {
        text: "NAME",
        value: 'name',
      },
    ],
  },
  planVisibility:{
    filterFunction: "getOrganizationByName",
    fetchDataFunction: "getOrgsPlanVisibility",
    sortOptions:[
      {
        text: 'ID',
        value: 'id',
      },
      { 
        text: 'ORGANIZATION',
        value: 'name',
      },
      { 
        text: 'STATUS',
        value: 'active',
      },
      { 
        text: 'PRIVATE',
        value: 'is_private',
      },
      {
        text: "CATEGORY",
        value: 'enum_plan_category'
      },
      { 
        text: 'UPDATED AT',
        value: 'updated_at',
      },
      { 
        text: 'CREATED AT',
        value: 'created_at',
      }
    ],
    searchOptions: [
      {
        text: "ID",
        value: 'id',
      },
      {
        text: "NAME",
        value: 'name',
      },
    ],
  },
  IPWhitelist:{
    filterFunction: "getOrganizationByName",
    fetchDataFunction: "getIPWhitelistByUserId",
    sortOptions:[
      {
        text: 'ID',
        value: 'id',
      },
      { 
        text: 'IP',
        value: 'ip',
      },
      { 
        text: 'STATUS',
        value: 'active',
      },
      { 
        text: 'UPDATED AT',
        value: 'updated_at',
      },
    ],
    searchOptions: [
      {
        text: "ID",
        value: 'id',
      },
    ],
  },
  userSessions:{
    filterFunction: "getOrganizationByName",
    fetchDataFunction: "getUserActiveSessionsByUserId",
    sortOptions:[
      {
        text: 'ID',
        value: 'id',
      },
      { 
        text: 'LAST ACTIVITY',
        value: 'last_activity',
      },
      { 
        text: 'CREATED AT',
        value: 'created_at',
      }
    ],
    searchOptions: [
      {
        text: "ID",
        value: 'id',
      },
    ],
  },
  userManagementsession:{
    filterFunction: "getOrganizationByName",
    fetchDataFunction: "fetchUserToManage",
    sortOptions:[
      {
        text: 'ID',
        value: 'id',
      },
      { 
        text: 'LAST ACTIVITY',
        value: 'last_activity',
      },
      { 
        text: 'CREATED AT',
        value: 'created_at',
      }
    ],
    searchOptions: [
      {
        text: "ID",
        value: 'id',
      },
    ],
  },
  userManagementip_whitelist:{
    filterFunction: "getOrganizationByName",
    fetchDataFunction: "fetchUserToManage",
    sortOptions:[
      {
        text: 'ID',
        value: 'id',
      },
      { 
        text: 'IP',
        value: 'ip',
      },
      { 
        text: 'STATUS',
        value: 'active',
      },
      { 
        text: 'UPDATED AT',
        value: 'updated_at',
      },
    ],
    searchOptions: [
      {
        text: "ID",
        value: 'id',
      },
    ],
  },
  servicesByTransmissionGroup:{
    fetchDataFunction: "fetchServicesByTransmissionGroup",
    sortOptions:[
      {
        text: 'ID',
        value: 'id',
      },
      { 
        text: 'IP',
        value: 'ip',
      },
      { 
        text: 'STATUS',
        value: 'active',
      },
      { 
        text: 'UPDATED AT',
        value: 'updated_at',
      },
    ],
    searchOptions: [
      {
        text: "ID",
        value: 'id',
      },
    ],
  },
  eventsByTransmissionGroup:{
    fetchDataFunction: "fetchEventsByTransmissionGroup",
    sortOptions:[
      {
        text: 'ID',
        value: 'id',
      },
      { 
        text: 'IP',
        value: 'ip',
      },
      { 
        text: 'STATUS',
        value: 'active',
      },
      { 
        text: 'UPDATED AT',
        value: 'updated_at',
      },
    ],
    searchOptions: [
      {
        text: "ID",
        value: 'id',
      },
    ],
  },
  serviceEvents:{
    fetchDataFunction: "getEventsByService",
    sortOptions:[
      {
        text: 'ID',
        value: 'id',
      },
      { 
        text: 'IP',
        value: 'ip',
      },
      { 
        text: 'STATUS',
        value: 'active',
      },
      { 
        text: 'UPDATED AT',
        value: 'updated_at',
      },
    ],
    searchOptions: [
      {
        text: "ID",
        value: 'id',
      },
    ],
  },
  eventServices:{
    fetchDataFunction: "getServicesByEvent",
    sortOptions:[
      {
        text: 'ID',
        value: 'id',
      },
      { 
        text: 'IP',
        value: 'ip',
      },
      { 
        text: 'STATUS',
        value: 'active',
      },
      { 
        text: 'UPDATED AT',
        value: 'updated_at',
      },
    ],
    searchOptions: [
      {
        text: "ID",
        value: 'id',
      },
    ],
  },
  scriptList:{
    fetchDataFunction: "getAllScriptsByTransmission",
    sortOptions:[
      { 
        text: 'ACTIVE',
        value: 'active',
      },
      { 
        text: 'CREATED AT',
        value: 'created_at',
      },
    ],
    searchOptions: [
    ],
  },
  serviceWebhook: {
    fetchDataFunction: "getAllWebhooksByService",
    sortOptions: [
      {
        text: 'ACTIVE',
        value: 'active',
      },
      {
        text: 'CREATED AT',
        value: 'created_at'
      },
    ],
    searchOptions: [
    ],
  },
  integrator_services: {
    fetchDataFunction: "getAllWebServiceByOrgID",
    sortOptions: [
      {
        text: 'ACTIVE',
        value: 'active',
      },
      {
        text: 'CREATED AT',
        value: 'created_at'
      },
    ],
    searchOptions: [
      {
        text: "ID",
        value: 'id',
      },
    ],
  },
});
