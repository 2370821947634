export default {
  state: {
    isBTableBusy: true,
		tableTotalItems: null, 
  },
  getters: {
    getIsBTableBusy: (state) => {
      return state.isBTableBusy;
    },
		getTableTotalItems: (state) => {
			return state.tableTotalItems;
		},
  },
  mutations: {
    SET_IS_BTABLE_BUSY(state, busyData) {
      state.isBTableBusy = busyData;
    },
		SET_TABLE_TOTAL_ITEMS(state, payload) {
      state.tableTotalItems= payload;
    },
  },
  actions: {
    setTotalTableItems({ commit }, payload){
      commit('SET_TABLE_TOTAL_ITEMS', payload);
    },
    resolveFilterUrl(_, data) {
			const defaultFilters = {
				per_page: 10,
				page: 1,
			};

			let filters = {
				...defaultFilters,
				...data.filters,
			}

			let url = data.url;
			let has_params = false;

			Object.keys(filters).forEach((key) => {
				if(filters[key] !== null) {
					if(has_params) {
						url += "&";
					} else {
						url += "?";
					}
					

					url += `${key}=${filters[key]}`;

					has_params = true;
				}
			});      
      return url;
    },
  },
};
