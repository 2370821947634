import axiosIns from "@/libs/axios";
import Vue from "vue";
import { makeToast } from "@/layouts/components/Popups";
import i18n from '@/libs/i18n'
import { axiosCall } from "@/store/AxiosCaller";

export default {
  namespaced: true,
  state: {
    release_by_service: {},
    release_info: []
  },
  getters: {
    getReleases: (state) => (serviceID) => {
      if (!state.release_by_service[serviceID]) return;

      return state.release_by_service[serviceID];
    },
    getReleaseInfo: (state) => (releaseID)  => {
      if (!state.release_info) return;

      let r = state.release_info.find(element => element.id == releaseID)
      return r;
    },
  },
  mutations: {
    PUT_RELEASES_BY_SERVICE(state , payload){
      const {serviceID, data} = payload;

      Vue.set(state.release_by_service, serviceID, data)
    },
    DELETE_RELEASE(state, payload){
      const { serviceID, releaseID } = payload;

      let releaseIdx = 0

      releaseIdx = state.release_by_service[serviceID].findIndex((el) => el.id == releaseID)

      state.release_by_service[serviceID].splice(releaseIdx, 1);
    },
    INSERT_RELEASE(state, payload){
      let {serviceID, data} = payload

      data.integrator_service_id = data.integrator_service.id;
      delete data.integrator_service
      data.enum_release_tag.id = data.enum_release_tag.id

      let latestIdx = state.release_by_service[serviceID].findIndex((el) => el.enum_release_tag.id == 1);
      if (latestIdx && latestIdx != -1){ //when 'findIndex' doesn't find anything it returns -1
        state.release_by_service[serviceID][latestIdx].enum_release_tag.id = 3
      }

      state.release_by_service[serviceID].push(data);
      
    },
    INSERT_RELEASE_INFO(state , payload){
      state.release_info.push(payload.data);
    },
    UPDATE_TAG_ID(state, payload){
      let {serviceID, releaseID, newTagId} = payload;
      
      let releaseIdx = state.release_by_service[serviceID].findIndex((el) => el.id == releaseID);
      if (releaseIdx != -1){
        state.release_by_service[serviceID][releaseIdx].enum_release_tag.id = newTagId;
      }
    }
  },


  actions: {
    getReleasesByService({ commit }, payload){
      const { serviceID } = payload;

      return axiosCall({
        url: `/module/integrator_service_release/by/integrator_service/${serviceID}`,
        method: 'get'
      })
      .then((resp)=>{
        commit('PUT_RELEASES_BY_SERVICE', { serviceID, data: resp})
        return resp
      })
    },
    getReleaseByID({ commit, getters }, payload){
      const { releaseID, toFlux = false } = payload;

      return axiosCall({
        url: `/module/integrator_service_release/${releaseID}`,
        method: 'get',
        treatResponse: resp => resp
      })
      .then((resp)=>{
        if (toFlux) {
          return resp.data.data
        } else {
          commit('INSERT_RELEASE_INFO', { data: resp.data.data })
          return resp.data.data
        }
      })
    },
    
    deleteRelease({ commit }, payload) {
      const { serviceID, releaseID } = payload;
      
      return axiosCall({
        url: `/module/integrator_service_release/${releaseID}/`,
        method: 'delete'
      })
      .then((resp)=>{
        commit('DELETE_RELEASE', { serviceID, releaseID });
        return
      })
    },
    insertRelease({ commit }, payload) {
      const { serviceID, data } = payload;
      const postData = {
        "integrator_service": parseInt(data.integrator_service),
        "label": data.label
      }

      return axiosCall({
        url:  `/module/integrator_service_release/`,
        method: 'post',
        payload: postData,
      })
      .then((resp)=>{
        commit('INSERT_RELEASE', { serviceID: serviceID, data: resp, })
        return resp;
      })
    },
    updateReleaseTag({ commit }, payload) {
      const { serviceID, releaseID, newTagId } = payload;
      const postData = {
        "new_value": newTagId,
      }

      return axiosCall({
        url: `/module/integrator_service_release/${releaseID}/enum_release_tag`,
        method: 'patch',
        payload: postData,
      })
      .then((resp)=>{
        commit('UPDATE_TAG_ID', { serviceID, releaseID, newTagId});
        return 
      })
    },
  }
};