import axiosIns from "@/libs/axios";
import Vue from 'vue';
import { makeToast } from "@/layouts/components/Popups";
import i18n from '@/libs/i18n'
import { axiosCall } from "../AxiosCaller";

export default {
  state: {
    scheduleVersions: {},
    schedules: {},
  },
  getters: {
    getScheduleVersions: (state) => (transmissionID, schedulerID) => {
      if (!state.scheduleVersions || !state.scheduleVersions[transmissionID]) return null
      return state.scheduleVersions[transmissionID][schedulerID];
    },
    getSchedules: (state) => (id) => {
      return state.schedules[id];
    },
    getScheduleByID: (state) => (transmissionID, schedulerID) => {
      if (!state.schedules[transmissionID] || !state.schedules[transmissionID].length) return null
      
      const index = state.schedules[transmissionID].findIndex(el => el.id == schedulerID);
      
      return state.schedules[transmissionID][index]
    },
    getScheduleByIdentifier: (state) => (transmissionID, schedulerIdentifier) => {
      if (!state.schedules[transmissionID] || !state.schedules[transmissionID].length) return null
      
      const index = state.schedules[transmissionID].findIndex(el => el.identifier == schedulerIdentifier);
      
      return state.schedules[transmissionID][index]
    }
  },
  mutations: {
    SET_SCHEDULES(state, payload) {
      Vue.prototype.$set(state.schedules, payload.transmission_id, payload.data);
    },
    SET_SCHEDULE_BY_ID(state, payload) {
      if (!state.schedules[payload.transmissionID]) {
        state.schedules[payload.transmissionID] = []
        state.schedules[payload.transmissionID].push(payload.data) 
        return
      }
      const index = state.schedules[payload.transmissionID].findIndex(el => el.id == payload.schedulerID);

      Vue.prototype.$set(state.schedules[payload.transmissionID], index, payload.data)
    },
    TOGGLE_SCHEDULE_PARAM(state, payload) {
      if (!state.schedules[payload.transmission_id]) return 
      
      const index = state.schedules[payload.transmission_id].findIndex(el => el.id == payload.id);
      
      Vue.prototype.$set(state.schedules[payload.transmission_id][index], payload.param, payload.new_value);

      Vue.prototype.$set(state.schedules, payload.transmission_id, state.schedules[payload.transmission_id].sort(function(a, b) { 
        return b.favorite - a.favorite  || b.active - a.active ||  a.name.localeCompare(b.name);
      }));
    },
    PUSH_SCHEDULE_PARAM(state, payload) {
      if (!state.schedules[payload.transmissionID]) return 
      
      const index = state.schedules[payload.transmissionID].findIndex(el => el.id == payload.schedulerID);
      
      if (!state.schedules[payload.transmissionID][index][payload.param]) state.schedules[payload.transmissionID][index][payload.param] = []

      state.schedules[payload.transmissionID][index][payload.param].push(payload.new_value);
    },
    ADD_SCHEDULE(state, payload) {
      state.schedules[payload.transmissionID].unshift(payload.schedule)

      Vue.prototype.$set(state.schedules, payload.transmissionID, state.schedules[payload.transmissionID].sort(function(a, b) { 
        return b.favorite - a.favorite  || b.active - a.active ||  a.name.localeCompare(b.name);
      }));
    },
    DELETE_SCHEDULE(state, payload) {
      Vue.prototype.$set(state.schedules, payload.transmission_id, state.schedules[payload.transmission_id].filter(function(value) {
        return value.id != payload.id;
      }));
    },
    SET_SCHEDULE_VERSIONS(state, payload) {

      if (!state.scheduleVersions) state.scheduleVersions = {};
      if (!state.scheduleVersions[payload.transmissionID]) Vue.prototype.$set(state.scheduleVersions, payload.transmissionID, {});

      Vue.prototype.$set(state.scheduleVersions[payload.transmissionID], payload.schedulerID, payload.data)
    },
    DELETE_SCHEDULE_VERSION(state, payload) {
      Vue.prototype.$set(state.scheduleVersions[payload.transmissionID], payload.schedulerID, state.scheduleVersions[payload.transmissionID][payload.schedulerID].filter(function(value) {
        return value.id != payload.versionID;
      }));
    },
    ADD_SCHEDULE_VERSION(state, payload) {
      if (!state.scheduleVersions[payload.transmissionID]) state.scheduleVersions[payload.transmissionID] = {}

      state.scheduleVersions[payload.transmissionID][payload.schedulerID].forEach((value)   => {
        value.active = '0'
      })

      state.scheduleVersions[payload.transmissionID][payload.schedulerID].unshift(payload.version)
    },
    TOOGLE_SCHEDULE_VERSION(state, payload) {
      if (!state.scheduleVersions[payload.transmissionID] || !state.scheduleVersions[payload.transmissionID][payload.schedulerID]) {
        return
      } 
      
      const index = state.scheduleVersions[payload.transmissionID][payload.schedulerID].findIndex(el => el.id == payload.versionID);
      
      Vue.prototype.$set(state.scheduleVersions[payload.transmissionID][payload.schedulerID][index], payload.param, payload.new_value);

      Vue.prototype.$set(state.scheduleVersions[payload.transmissionID], payload.schedulerID, state.scheduleVersions[payload.transmissionID][payload.schedulerID].sort(function(a, b) { 
        return b.active - a.active ||  a.name.localeCompare(b.name);
      }));
    }
  },
  actions: {
    getScheduleLogs({}, payload) {
      let query = "";
      if(payload.from) {
        query += `from=${payload.from}`;
      }

      if(payload.to) {
        if(payload.from) {
          query += "&";
        }

        query += `to=${payload.to}`;
      }

      return axiosCall({
        url: `/central/${payload.transmission_id}/elastic/logs/scheduler/${payload.scheduler_id}?${query}`,
        method: 'get'
      })
    },
//     getScheduleLogsPaginated({}, payload) {
//       //this may be a Legacy call
//       console.log('salad')
//       return new Promise((resolve, reject) => {
//         let config = {
//           headers: {
//             "x-api-url": `/central/${payload.transmission_id}/elastic/logs/paginate/${payload.scroll_id}`,
//             session: `Session ${localStorage.getItem("sessionKey")}`,
//           },
//         };

//        console.trace(); axiosIns
//           .get("/", config)
//           .then((resp) => {
//             if (resp.status == 403){
//               makeToast({
//                 title: i18n.t('common.toast.forbidden.title'),
//                 text: i18n.t('common.toast.forbidden.message'),
//                 variant: "danger",
//                 icon: "SlashIcon",
//               })              
//             } 
// console.trace();if (resp.status == 200 && !resp.data.error) {
//               resolve(resp.data.data);
//             } else {
//               throw resp;
//             }
//           })
//           .catch((error) => {
//             if (error?.response?.status == 403){
//               makeToast({
//                 title: i18n.t('common.toast.forbidden.title'),
//                 text: i18n.t('common.toast.forbidden.message'),
//                 variant: "danger",
//                 icon: "SlashIcon",
//               })
//             }
//             reject(error);
//           });
//       })
//     },
    getSchedules({ commit, getters }, payload) {
      return axiosCall({
        url: `/${payload.transmissionID}/trigger/scheduler`,
        method: 'get',
      })
      .then((resp)=>{        
        commit('SET_SCHEDULES', {
          transmission_id: payload.transmissionID,
          data: resp,
        });
        return resp
      })
    },
    toggleScheduleFavorite({ commit }, payload) {
      let data ={
        new_value: payload.new_value,
      }

      let r = axiosCall({
        url: `/${payload.transmissionID}/trigger/scheduler/${payload.id}/favorite`,
        method: 'patch',
        payload: data
      })

      r.then((resp)=>{
        commit('TOGGLE_SCHEDULE_PARAM', {
          param: "favorite",
          transmission_id: payload.transmissionID,
          new_value: payload.new_value,
          id: payload.id,
        });
      })

      return r
    },
    toggleScheduleActive({ commit }, payload) {
      let data = {
        new_value: payload.new_value,
      }
      return axiosCall({
        url: `/${payload.transmissionID}/trigger/scheduler/${payload.id}/active`,
        method: 'patch',
        payload: data
      })
      .then((resp)=>{
        commit('TOGGLE_SCHEDULE_PARAM', {
          param: "active",
          transmission_id: payload.transmissionID,
          new_value: payload.new_value,
          id: payload.id,
        });

        return resp;
      })
    },
    deleteSchedule({ commit }, payload) {
      return axiosCall({
        url: `/${payload.transmissionID}/trigger/scheduler/${payload.id}`,
        method: 'delete',
      }).then((resp)=>{
        commit('DELETE_SCHEDULE', {
          transmission_id: payload.transmissionID,
          id: payload.id,
        });
        return resp
      })
    },
    insertScheduler({ commit }, payload) {
      return axiosCall({
        url: `/${payload.transmissionID}/trigger/scheduler`,
        method: 'post',
        payload: payload.data,
        treatResponse: resp => resp
      }).then((resp)=>{
        commit('ADD_SCHEDULE', { schedule: resp.data.data, transmissionID: payload.transmissionID })
        return resp.data
      })
    },
    insertSchedulerQuery({ commit }, payload) {
      let config = {
        headers: {
          "x-api-url": `/${payload.transmissionID}/trigger/scheduler_query`,
          session: `Session ${localStorage.getItem("sessionKey")}`,
        },
      };

      let postData = {
        data: {
          "scheduler": payload.schedulerID,
          "field_key": payload.item.key,
          "field_value": payload.item.key,
          "hidden": payload.item.hidden || false
        }
      }
      return new Promise((resolve, reject) => {
       console.trace(); axiosIns
          .post("/", postData, config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {
              commit('PUSH_SCHEDULE_PARAM', 
                {
                  param: "queries",
                  transmissionID: payload.transmissionID,
                  new_value: resp.data.data,
                  schedulerID: payload.schedulerID,
                }
              )
              resolve({ data: resp.data.data, field: 'queries' });
            } else {
              throw resp
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error);
          });
      });
    },
    insertSchedulerHeader({ commit }, payload) {
      let config = {
        headers: {
          "x-api-url": `/${payload.transmissionID}/trigger/scheduler_header`,
          session: `Session ${localStorage.getItem("sessionKey")}`,
        },
      };
      let postData = {
        data: {
          "scheduler": payload.schedulerID,
          "field_key": payload.item.key,
          "field_value": payload.item.key,
          "hidden": payload.item.hidden || false
        }
      }
      return new Promise((resolve, reject) => {
       console.trace(); axiosIns
          .post("/", postData, config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {

              commit('PUSH_SCHEDULE_PARAM', 
                {
                  param: "headers",
                  transmissionID: payload.transmissionID,
                  new_value: payload.item,
                  schedulerID: payload.schedulerID,
                }
              )

              resolve({ data: resp.data.data, field: 'headers' });
            } else {
              throw resp
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error);
          });
      });
    },
    getSchedulerByID({ dispatch, getters, commit }, payload) {

      return axiosCall({
        url: `/${payload.transmissionID}/trigger/scheduler/${payload.schedulerID}`,
        method: 'get',
        treatResponse: resp => resp
      })
      .then((resp)=>{
        const data = getters.getSchedules(payload.transmissionID);
        if(data === undefined ) {
          dispatch('getSchedules', {
            transmissionID: payload.transmissionID,
          });
        }
        return resp.data
      })
    },
    getSchedulerByIdentifier({ dispatch, getters, commit }, payload) {
      
      const data = getters.getScheduleByIdentifier(payload.transmission_id, payload.identifier);
      if(data !== undefined && !payload.force_update) {
        return new Promise((resolve, reject) => {resolve(data)})
      }

      return axiosCall({
        url: `/${payload.transmission_id}/trigger/scheduler/by/identifier/${payload.identifier}`,
        method: 'get',
        treatResponse: resp => resp
      })
      .then((resp)=>{
        const data = getters.getSchedules(payload.transmission_id);
        if(data === undefined ) {
          dispatch('getSchedules', {
            transmissionID: payload.transmission_id,
          });
        }

          commit('SET_SCHEDULE_BY_ID',
            {
              transmissionID: payload.transmission_id,
              schedulerID: payload.schedulerID,
              data: resp.data.data,
            }
          )
        return resp.data;
      })
    },
    patchScheduler({ commit }, payload) {
      return new Promise((resolve, reject) => {

        let config = {
          headers: {
            "x-api-url": `/${payload.transmissionID}/trigger/scheduler/${payload.schedulerID}/${payload.field}`,
            session: `Session ${localStorage.getItem("sessionKey")}`,
          },
        };

        let patchData = {
          data: {
            new_value: payload.new_value
          }
        }

       console.trace(); axiosIns
          .patch("/", patchData, config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {

              commit('TOGGLE_SCHEDULE_PARAM', {
                param: payload.field,
                transmission_id: payload.transmissionID,
                new_value: payload.new_value,
                id: payload.schedulerID,
              });
              
              resolve(resp.data.data);
            } else {
              throw resp;
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error);
          });
      });
    },
    getSchedulerVersionsByID({ commit }, payload) {
      return axiosCall({
        url: `/trigger/scheduler_script/by/scheduler/${payload.transmissionID}/${payload.schedulerID}`,
        method: 'get'
      })
      .then((resp)=>{
        commit('SET_SCHEDULE_VERSIONS', { transmissionID: payload.transmissionID, schedulerID: payload.schedulerID, data: resp })
        return true
      })
    },
    insertScheduleScript({ commit }, payload) {
      let config = {
        headers: {
          "x-api-url": `/trigger/scheduler_script`,
          session: `Session ${localStorage.getItem("sessionKey")}`,
        },
      };
      let postData = {
        data: {
          central_transmission: parseInt(payload.transmissionID),
          scheduler: payload.schedulerID,
          name: payload.name
        }
      }
      return new Promise((resolve, reject) => {
       console.trace(); axiosIns
          .post("/", postData , config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {
              
              commit('ADD_SCHEDULE_VERSION', { schedulerID: payload.schedulerID, transmissionID: payload.transmissionID, version: resp.data.data })
              resolve(resp.data.data);
            } else {
              throw resp
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error);
          });
      });
    },
    getSchedulerScriptVersionByID(_, payload) {
      return axiosCall({
        url: `/trigger/scheduler_script/${payload.versionID}`,
        method: 'get',
        treatResponse: resp => resp.data
      })
    },
    deleteScheduleScript({ commit }, payload) {

      return new Promise((resolve, reject) => {

        let config = {
          headers: {
            "x-api-url": `/trigger/scheduler_script/${payload.versionID}`,
            session: `Session ${localStorage.getItem("sessionKey")}`,
          },
        };
       console.trace(); axiosIns
          .delete("/", config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {
              
              commit('DELETE_SCHEDULE_VERSION', { ...payload })
              resolve(resp.data);
            } else {
              throw resp;
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error);
          });
      });
    },
    patchSchedulerVersion({ commit }, payload) {
      return new Promise((resolve, reject) => {

        let config = {
          headers: {
            "x-api-url": `/trigger/scheduler_script/${payload.versionID}/${payload.field}`,
            session: `Session ${localStorage.getItem("sessionKey")}`,
          },
        };

        let patchData = {
          data: {
            new_value: payload.new_value
          }
        }

       console.trace(); axiosIns
          .patch("/", patchData, config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {

              commit('TOOGLE_SCHEDULE_VERSION', {
                param: payload.field,
                transmissionID: payload.transmissionID,
                new_value: payload.new_value,
                schedulerID: payload.schedulerID,
                versionID: payload.versionID
              });
              
              resolve(resp.data.data);
            } else {
              throw resp;
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error);
          });
      });
    },
    deleteWebhookSchedulerInfo({ commit }, payload) {
      return new Promise((resolve, reject) => {
        let config = {
          headers: {
            "x-api-url": `/${payload.transmissionID}/trigger/${payload.type}/${payload.itemID}`,
            session: `Session ${localStorage.getItem("sessionKey")}`,
          },
        };

       console.trace(); axiosIns
          .delete("/", config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {

              resolve(resp.data.data);
            } else {
              throw resp;
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error);
          });
      })
    },
    patchSchedulerWebhookInfo({ commit }, payload) {
      return new Promise((resolve, reject) => {
        let config = {
          headers: {
            "x-api-url": `/${payload.transmissionID}/trigger/${payload.type}/${payload.itemID}/${payload.field}`,
            session: `Session ${localStorage.getItem("sessionKey")}`,
          },
        };

       console.trace(); axiosIns
          .patch("/", {
            data: {
              new_value: payload.new_value,
            }
          }, config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {
              resolve(resp.data.data);
            } else {
              throw resp;
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error);
          });
      })
    },
    getCurrentScheduleVersion({ commit }, payload) {

      return axiosCall({
        url:  `/${payload.transmissionID}/trigger/scheduler/${payload.schedulerID}/current/script`,
        method: 'get',
        treatResponse: resp => resp.data
      })
    },
    runScheduleOnce({ commit }, payload) {
      return axiosCall({
        url: `/${payload.transmissionID}/execute/trigger/scheduler/${payload.id}`,
        method: 'post',
        payload: {},
        treatResponse: resp => resp.data
      })
    },

  }
};