export default [
    {
        path: '',
        name: 'login',
        component: () =>
            import ('@/views/pages/login/Login.vue'),
        meta: {
            title: "FiqOn - Login",
            layout: 'full',
            resource: 'Auth',
            redirectIfLoggedIn: true,
        },
    },
    {
        path: '/login/register',
        name: 'register',
        component: () =>
            import ('@/views/pages/login/Register.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',
            redirectIfLoggedIn: false,
        },
    },
    {
        path: '/login/verify-email',
        name: 'verify-email',
        component: () =>
            import ('@/views/pages/login/VerifyEmail.vue'),
        meta: {
            title: "FiqOn - Verify Email",
            layout: 'full',
            // redirectIfLoggedIn: true,
        },
    },
    {
        path: '/login/verify-phone',
        name: 'verify-phone',
        component: () =>
            import ('@/views/pages/login/VerifyPhone.vue'),
        meta: {
            title: "FiqOn - Verify Phone",
            layout: 'full',
            // redirectIfLoggedIn: true,
        },
    },
    {
        path: '/login/forgot-password',
        name: 'forgot-password',
        component: () =>
            import ('@/views/pages/login/ForgotPassword.vue'),
        meta: {
            resource: "Auth",
            title: "FiqOn - Forgot Password",
            layout: 'full',
            resource: 'Auth',
            redirectIfLoggedIn: true,
        },
    },
    {
        path: '/login/reset-password/:solicitation_code',
        name: 'reset-password',
        component: () =>
            import ('@/views/pages/login/ResetPassword.vue'),
        meta: {
            title: "FiqOn - Reset Password",
            layout: 'full',
            resource: 'Auth',
        },
    },
    {
        path: '/login/access-point-validation',
        name: 'access-point-validation',
        component: () =>
            import ('@/views/pages/login/AccessPointValidation.vue'),
        meta: {
            title: "FiqOn - Access Point Validation",
            layout: 'full',
            resource: 'Auth'
        },
    },
]