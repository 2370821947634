export const tableFields = Object.freeze({
    orgPlans: [{
            key: "id",
            label: "ID",
            sortable: false,
        },
        {
            key: "name",
            label: "NAME",
            sortable: false,
        },
        {
            key: "status",
            label: "STATUS",
            sortable: false,
        },
        {
            key: "is_private",
            label: "PRIVATE",
            sortable: false,
        },
        {
            key: "category",
            label: "CATEGORY",
            sortable: false,
        },
        {
            key: "created_at",
            label: "CREATED AT",
            sortable: false,
        },
        {
            key: "actions",
            label: "ACTIONS",
        },
    ],
    orgServers: [{
            key: "id",
            label: "ID",
            sortable: false,
        },
        {
            key: "fullname",
            label: "NAME",
            sortable: false,
        },
        {
            key: "created_at",
            label: "CREATED AT",
            sortable: false,
        },
        {
            key: "role",
            label: "ROLE",
            sortable: false,
        },
        {
            key: "email",
            label: "EMAIL",
            sortable: false,
        },
        {
            key: "actions",
            label: "ACTIONS",
        },
    ],
});