export const errorMessages = Object.freeze({
  default: {
    title: "Error!",
    text: "",
    variant: "danger",
    icon: "AlertCircleIcon",
    solid: true,
  },
  1: {
    title: "Login Failed!",
    text: "Invalid Credentials",
    variant: "danger",
    icon: "AlertCircleIcon",
    solid: true,
  },
  2: {
    title: "New access location detected!",
    text: "An authorization request was sent to your email",
    variant: "info",
    icon: "MailIcon",
    solid: true,
  },
  // 3:{
  //     'title'     : 'New access location detected!',
  //     'text'      : 'An authorization request was sent to your email',
  //     'variant'   : 'info',
  //     'icon'      : 'MailIcon',
  //     'solid'     : true
  // },
  4: {
    title: "Username already in use!",
    text: "You can try again with a different one.",
    variant: "warning",
    icon: "UserIcon",
    solid: true,
  },
  5: {
    title: "Email already in use!",
    text: "Aren't you already an user?",
    variant: "danger",
    icon: "XCircleIcon",
    solid: true,
  },
  6: {
    title: "Weak Password!",
    text:
      "Your password must have at least 8 characters, including a number, a special character and a uppercase letter. Example: 'Fiqon#20'",
    variant: "danger",
    icon: "XCircleIcon",
    solid: true,
  },
  400: {
    title: "Bad request!",
    text: "400",
    variant: "danger",
    icon: "AlertCircleIcon",
    solid: true,
  },
  403: {
    title: "Forbidden!",
    text: "403",
    variant: "danger",
    icon: "AlertCircleIcon",
    solid: true,
  },
  500: {
    title: "Something went wrong!",
    text: "Try again.",
    variant: "warning",
    icon: "RssIcon",
    solid: true,
  },
  reset_password_solicitation: {
    title: "Something went Wrong!",
    text: "Couldn't send a password reset link to your email.",
    variant: "danger",
    icon: "XIcon",
    solid: true,
  },
  password_reset: {
    title: "Password Redefined Successfuly!",
    text: "",
    variant: "success",
    icon: "KeyIcon",
    solid: true,
  },
  ///////////////////////////////
  deleteOrgById: {
    title: "Canceled !",
    text: "Organization not deleted.",
    variant: "warning",
    icon: "WarningIcon",
    solid: true,
  },
  addOrganization: {
    title: "Something went wrong!",
    text: "Error adding organization.",
    variant: "danger",
    icon: "XIcon",
    solid: true,
  },
  deleteUserFromOrgById: {
    title: "Something went wrong!",
    text: "Error deleting user from Organization.",
    variant: "danger",
    icon: "XIcon",
    solid: true,
  },
  editOrganizationUser: {
    title: "Something went Wrong!",
    text: "User role not Updated.",
    variant: "danger",
    icon: "XIcon",
    solid: true,
  },
  addUserToOrganization: {
    title: "Something went Wrong!",
    text: "User not added to Organization.",
    variant: "danger",
    icon: "XIcon",
    solid: true,
  },
  //SERVERS
  deleteServerById: {
    title: "Cannot Delete!",
    text: "This Server have dependencies.",
    variant: "danger",
    icon: "XIcon",
    solid: true,
  },
  addServer: {
    title: "Something went Wrong!",
    text: "Server not Added.",
    variant: "danger",
    icon: "XIcon",
    solid: true,
  },
  editServer: {
    title: "Something went Wrong!",
    text: "Server not Updated.",
    variant: "danger",
    icon: "XIcon",
    solid: true,
  },
  updateServer: {
    title: "Something went Wrong!",
    text: "Server Info not Updated.",
    variant: "danger",
    icon: "XIcon",
    solid: true,
  },
  isPublicEdit: {
    title: "Something went Wrong!",
    text: "Server public status not Updated.",
    variant: "danger",
    icon: "XIcon",
    solid: true,
  },
  addOrganizationToServer: {
    title: "Something went Wrong!",
    text: "Organization not Added to Server",
    variant: "danger",
    icon: "XIcon",
    solid: true,
  },
  //PROFILE
  editUser: {
    title: "Something went Wrong!",
    text: "User Info not Updated.",
    variant: "danger",
    icon: "XIcon",
    solid: true,
  },
  deleteIPById: {
    title: "Something went Wrong!",
    text: "Access Point not deleted, try again.",
    variant: "danger",
    icon: "XIcon",
    solid: true,
  },
  deleteSession: {
    title: "Something went Wrong!",
    text: "Session not deleted, try again.",
    variant: "danger",
    icon: "XIcon",
    solid: true,
  },
  embedUserSession:{
    title: "Success!",
    text: `Session embeded`,
    variant: "success",
    icon: "CheckIcon",
    solid: true,
  },
	//PLAN
  deletePlanById: {
    title: "Something went Wrong!",
    text: "Plan could not be deleted",
    variant: "danger",
    icon: "XIcon",
    solid: true,
  },
	addNewPlan:{
		title: "Something went Wrong!",
    text: "Plan not Added",
    variant: "danger",
    icon: "XIcon",
    solid: true,
	},
  setPlanConfig:{
    title: "Something went Wrong!",
    text: "Configuration not Added",
    variant: "danger",
    icon: "XIcon",
    solid: true,
  },
  togglePlanIsPrivate:{
    title: "Something went Wrong!",
    text: "Could not update plan privacy :(",
    variant: "danger",
    icon: "XIcon",
    solid: true,
  },
  togglePlanIsMainConfig:{
    title: "Something went Wrong!",
    text: "Could not update main config :(",
    variant: "danger",
    icon: "XIcon",
    solid: true,
  },
  editPlan:{
    title: "Something went Wrong!",
    text: "Plan Info update failed!",
    variant: "danger",
    icon: "XIcon",
    solid: true,
  },
  editIsPlanPrivate:{
    title: "Something went Wrong!",
    text: "Privacy could not be updated",
    variant: "danger",
    icon: "XIcon",
    solid: true,
  },
  togglePlanIsPrivate:{
    title: "Something went Wrong!",
    text: "Privacy not updated",
    variant: "danger",
    icon: "XIcon",
    solid: true,
  },
  deletePlanConfigById:{
    title: "Something went Wrong!",
    text: "Couldn't delete plan configuration",
    variant: "danger",
    icon: "XIcon",
    solid: true,
  },
  clonePlanById:{
    title: "Something went Wrong!",
    text: "Couldn't clone plan configuration",
    variant: "danger",
    icon: "XIcon",
    solid: true,
  },
  deleteOrgFromPlanById:{
    title: "Something went Wrong!",
    text: "Couldn't delete plan visibility",
    variant: "danger",
    icon: "XIcon",
    solid: true,
  },
  editPlanConfigMetrics:{
    title: "Something went Wrong!",
    text: "Couldn't change plan metrics",
    variant: "danger",
    icon: "XIcon",
    solid: true,
  },
  editPlanConfig:{
    title: "Something went Wrong!",
    text: "Plan Config not updated, try again",
    variant: "danger",
    icon: "XIcon",
    solid: true,
  },
  // GROUP TRANSMISSION 
  planSelected:{
    title: "Select a Plan!",
    text: "",
    variant: "danger",
    icon: "HelpCircleIcon",
    solid: true,
  },
  baseServerSelected:{
    title: "Select a Base Server!",
    text: "",
    variant: "danger",
    icon: "HelpCircleIcon",
    solid: true,
  },
  systemLogServerSelected: {
    title: "Select a System Log Server!",
    text: "",
    variant: "danger",
    icon: "HelpCircleIcon",
    solid: true,
  },
  processedLogServerSelected:{
    title: "Select a Processed Log Server!",
    text: "",
    variant: "danger",
    icon: "HelpCircleIcon",
    solid: true,
  },
  createTransmissionGroup:{
    title: "Something went Wrong!",
    text: "Transmission not created",
    variant: "danger",
    icon: "XIcon",
    solid: true,
  },
  //SERVICE
  addService:{
    title: "Something went Wrong!",
    text: "Service not added",
    variant: "danger",
    icon: "XIcon",
    solid: true,
  },
  deleteServiceById: {
    title: "Something went Wrong!",
    text: "Service not deleted",
    variant: "danger",
    icon: "XIcon",
    solid: true
  },
  editService: {
    title: "Something went Wrong!",
    text: "Service not Edited",
    variant: "danger",
    icon: "XIcon",
    solid: true
  },
  addServicePermission: {
    title: "Something went Wrong!",
    text: "Permission not Added ",
    variant: "danger",
    icon: "XIcon",
    solid: true
  },
  deleteServicePermission: {
    title: "Something wwent Wrong!",
    text: "Permission not Deleted",
    variant: "danger",
    icon: "XIcon",
    solid: true
  },

  //COMPILED
  changeCompiledActive:{
    title: "common.toast.failed.title",
    text: "compiled.toast.change_active.error",
    variant: "danger",
    icon: "XIcon",
    solid: true,
  },
  compile:{
    title: "common.toast.failed.title",
    text: "compiled.toast.compile.error",
    variant: "danger",
    icon: "XIcon",
    solid: true,
  },
  //EVENT
  addEvent: {
    title: "Something went Wrong!",
    text: "Event not added",
    variant: "danger",
    icon: "XIcon",
    solid: true,
  },
  editEvent: {
    title: "Something went Wrong!",
    text: "Event not edited",
    variant: "danger",
    icon: "XIcon",
    solid: true
  },
  deleteEventById: {
    title: "Something went Wrong!",
    text: "Event not Deleted",
    variant: "danger",
    icon: "XIcon",
    solid: true
  },
  addEventPermission:{
    title: "Something went Wrong!",
    text: "Permission not added",
    variant: "danger",
    icon: "XIcon",
    solid: true,
  },
  detectObjectFieldsFromJSON: {
    title: "Something went Wrong!",
    text: "JSON not added",
    variant: "danger",
    icon: "XIcon",
    solid: true
  },
  deleteFullJSONObject: {
    title: "Something went Wrong!",
    text: "JSON not deleted",
    variant: "danger",
    icon: "XIcon",
    solid: true
  },
  canReceive: {
    title: "Something went Wrong!",
    text: "Can Receive not Changed",
    variant: "danger",
    icon: "XIcon",
    solid: true
  },
  canSend: {
    title: "Something went Wrong!",
    text: "Can Send not Changed",
    variant: "danger",
    icon: "XIcon",
    solid: true
  },

  //WEBHOOK
  addServiceWebhook: {
    title: "Something went Wrong!",
    text: "Webhook not Added",
    variant: "danger",
    icon: "XIcon",
    solid: true
  },
  deleteWebhookById: {
    title: "Something went Wrong!",
    text: "Webhook not deleted",
    variant: "danger",
    icon: "XIcon",
    solid: true
  },

  //AGREE WITH TERMS CREATE ACCOUNT
  agreeWithTerms: {
    title: "Agree with the terms!",
    text: "Please Agree with the privacy policy & use terms",
    variant: "danger",
    icon: "XIcon",
    solid: true,
  }
});
