import store from '@/store'
import { Ability } from '@casl/ability'
import { initialAbility } from './config'
// import store from '@/store/index'
//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this
const userData = JSON.parse(store.getters.getAbility)
const existingAbility = userData ? setUserNewAbility(userData) : initialAbility

function setUserNewAbility(userAccessLevel){
    let newAbility = null
  
    if(userAccessLevel.label === 'Normal') {  
      newAbility = [
          {
              action: 'read',
              subject: 'all',
          },
        //   {
        //       action: 'manage',
        //       subject: 'Auth',
        //   },
      ]
    }else if(userAccessLevel.label === 'GOD'){
      newAbility = [
          // {
          //     action: 'manage',
          //     subject: 'ACL',
          // },
          {
              action: 'manage',
              subject: 'all',
          },
      ]
    }else{
      newAbility = initialAbility
    }
  
    return newAbility
}

export default new Ability(existingAbility)
