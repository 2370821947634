import { axiosCall } from "@/store/AxiosCaller";

export default {
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    async getScheduleStats({ dispatch }, payload) {
      return axiosCall({
        url: `/central/${payload.transmission_id}/elastic/dashboard/scheduler/${payload.scheduler_id}`,
        method: 'get'
      })
    }
  }
};
