export default [
  {
    path: "/transmission/:transmissionID/services",
    name: "services",
    component: () => import("@/views/pages/services/Services.vue"),
    meta: {
      title: "FiqOn - Service",
      refreshTable: true,
      refreshInput: true,
      pageTitle: "Services",
      breadcrumb: [
        {
          text: "Transmission Dashboard",
          to: { name: "transmission-dashboard" },
        },
        {
          text: "Services",
          active: true,
        },
      ],
      loadTransmissionGroup: true,
			showTerminal: false,
    },
  },
  {
    path: "/transmission/:transmissionID/services/service-info/:serviceId",
    name: "service-info",
    component: () => import("@/views/pages/services/ServiceInfo.vue"),
    meta: {
      title: "FiqOn - Service Info",
      refreshTable: true,
      refreshInput: true,
      pageTitle: "Services",
      breadcrumb: [
        {
          text: "Services",
          to: { name: "services" },
        },
        {
          text: "Service Info",
          active: true,
        },
      ],
      loadTransmissionGroup: true,
			showTerminal: false,
    },
  },
  {
    path: "/transmission/:transmissionID/services/service-info/:serviceId/webhook-info/:webhookId",
    name: "webhook-info",
    component: () => import("@/views/pages/services/WebhookInfo.vue"),
    meta: {
      title: "FiqOn - Webhook Info",
      //refreshTable: true,
      refreshInput: true,
      pageTitle: "Webhook",
      breadcrumb: [
        {
          text: "Services",
          to: { name: "services" },
        },
        {
          text: "Webhooks",
          to: { name: "service-info", hash: '#2' },
        },
        {
          text: "Webhook Info",
          active: true,
        },
      ],
      loadTransmissionGroup: true,
			showTerminal: false,      
    },
  },
];
