export default [
    {
      path: '/:thirdPatyIdentifier/oauth-authentication' ,
      name: 'oauth-autentification',
      component: () => import ('@/views/pages/login/OauthAutentification.vue'),
      meta: {
        title: "FiqOn - OAuth Authentication",
        layout: 'full',
        resource: 'Auth',
      },
    },
  ]