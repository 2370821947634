export default [ 
  {
    path: '/transmission/:transmissionID/environments',
    name: 'environments',
    component: () =>
        import ('@/views/pages/environments/Environments.vue'),
    meta: {
      title: "FiqOn - Environments",
      refreshTable: true,
      pageTitle: "Environments",
      breadcrumb: [
        {
					text: 'Transmission Dashboard',
					to: { name: 'transmission-dashboard' }
				},
        {
          text: "Environments",
          active: true,
        },
      ],
			loadTransmissionGroup: true,
			showTerminal: false,
    },
  },
]