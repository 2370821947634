/*
  INPUT STRUCTURE{
    name: "organization-name",
    colSize: "12",
    fieldType: "input",
    type: "text",
    vmodel: "newOrgName",
    // variant: 'secondary',
    label: "Organization Name",
    placeholder: "ex: FiqOnOrg.",
    rules: 'required',
  },
  SELECT STRUCTURE{
    name: "role-name",
    colSize: "4",
    fieldType: "select",
    // variant: 'secondary',
    label: "Select a Role",
    vmodel: "role",
    value: "id",
    slotOption: "label",
    preRenderFunction: "fetchOrgRoles",
    rules: 'required',
  }
  SELECT 2 STRUCTURE{
    name: "service",
    colSize: "12",
    fieldType: "select2",
    vmodel: "org",
    // variant: 'secondary',
    label: "Search an Organization",
    noOptionLabel: "Type at least 3 letters",
    slotOption: "name",
    onSearch: "getOrganizationByName",
    rules: 'required',
  }
  TEXTAREA STRUCTURE{
    name: "query",
    colSize: "12",
    fieldType: "textarea",
    vmodel: "serverQuery",
    label: "Server Query",
    rows: 6,
    max_rows: 8,
    placeholder: "Server Query",
    rules: '',
  }
  SWITCH STRUCTURE{
    name: "plan config",
    colSize: "6",
    fieldType: "switch",
    vmodel: "newPlanConfig",
    label: "Set Plan Config now?",
    checked: true,
    variant: 'success',
    iconLeft: 'CheckIcon',
    iconRight: 'XIcon',
  }
 */
  export const modalStructure = Object.freeze({
    addOrganization: {
      modalConfig: {
        title: "add_organization",
        type: "add",
        size: "md",
        centered: true,
        backdropClickDontClose: true,
      },
      repeatForm: false, //if true col size must be max 10 cols per row
      structure: {
        row1: {
          col1: {
            name: "organization name",
            //colSize: "12",
            colSize: {
              xl: "12",
              lg: "12",
              md: "12",
              sm: "12",
              cols: "12"
            },
            fieldType: "input",
            type: "text",
            vmodel: "newOrgName",
            // variant: 'secondary',
            label: "add_organization",
            placeholder: "add_organization",
            rules: "required",
          },
        },
      },
      submitAction: "addOrganization",
      isActive: false,
    },
    editOrganizationById: {
      modalConfig: {
        title: "edit_org",
        type: "edit",
        size: "md",
        centered: true,
        backdropClickDontClose: true,
      },
      repeatForm: false, //if true col size must be max 10 cols per row
      structure: {
        row1: {
          col1: {
            name: "organization-name",
            //colSize: "12",
            colSize: {
              xl: "12",
              lg: "12",
              md: "12",
              sm: "12",
              cols: "12"
            },
            fieldType: "input",
            type: "text",
            vmodel: "editOrgName",
            // variant: 'secondary',
            label: "edit_org",
            placeholder: "edit_org",
            rules: "required",
          },
        },
      },
      submitAction: "editOrganizationById",
      isActive: false,
    },
    editOrganizationName: {
      modalConfig: {
        title: "Edit organization name",
        type: "edit",
        size: "md",
        centered: true,
        backdropClickDontClose: true,
      },
      repeatForm: false, //if true col size must be max 10 cols per row
      structure: {
        row1: {
          col1: {
            name: "organization-name",
            //colSize: "12",
            colSize: {
              xl: "12",
              lg: "12",
              md: "12",
              sm: "12",
              cols: "12"
            },
            fieldType: "input",
            type: "text",
            vmodel: "editOrgName",
            // variant: 'secondary',
            label: "edit_org",
            placeholder: "edit_org",
            rules: "required",
          },
        },
      },
      submitAction: "editOrganizationById",
      isActive: false,
    },
    addUserToOrganization: {
      modalConfig: {
        title: "add_user_org",
        type: "add",
        size: "lg",
        centered: false,
        backdropClickDontClose: true,
      },
      repeatForm: true, //if true col size must be max 10 cols per row
      structure: {
        row1: {
          col1: {
            name: "user-add-name",
            //colSize: "6",
            colSize: {
              xl: "6",
              lg: "6",
              md: "6",
              sm: "12",
              cols: "12"
            },
            fieldType: "select2",
            vmodel: "newUser",
            label: "add_user_org_name",
            noOptionLabel: "no_option_label",
            slotOption: "username",
            onSearch: "getUserByUsername",
            rules: "required",
          },
          col2: {
            name: "role-name",
            //colSize: "4",
            colSize: {
              xl: "6",
              lg: "6",
              md: "6",
              sm: "12",
              cols: "12"
            },
            fieldType: "select",
            // variant: 'secondary',
            label: "add_user_org_role",
            vmodel: "role",
            value: "id",
            slotOption: "label",
            preRenderFunction: "fetchOrgRoles",
            rules: "required",
          },
        },
      },
      submitAction: "addUserToOrganization",
      cloneFunction: "cloneAddUserOrg",
      isActive: false,
    },
    editOrganizationUser: {
      modalConfig: {
        title: "edit_org_user",
        type: "edit",
        size: "md",
        centered: true,
        backdropClickDontClose: true,
      },
      repeatForm: false, //if true col size must be max 10 cols per row
      structure: {
        row1: {
          col1: {
            name: "user-name",
            //colSize: "12",
            colSize: {
              xl: "12",
              lg: "12",
              md: "12",
              sm: "12",
              cols: "12"
            },
            fieldType: "select",
            vmodel: "role",
            label: "edit_org_user",
            value: "id",
            slotOption: "label",
            preRenderFunction: "fetchOrgRoles",
            rules: "required",
          },
        },
      },
      submitAction: "editOrganizationUser",
      isActive: false,
    },
    //SERVERS
    addServer: {
      modalConfig: {
        title: "add_server",
        type: "add",
        size: "xl",
        centered: true,
        backdropClickDontClose: true,
      },
      repeatForm: false, //if true col size must be max 10 cols per row
      structure: {
        row1: {
          col1: {
            name: "name",
            //colSize: "4",
            colSize: {
              xl: "4",
              lg: "4",
              md: "12",
              sm: "12",
              cols: "12"
            },
            fieldType: "input",
            type: "text",
            vmodel: "serverName",
            label: "add_server",
            placeholder: "add_server_name",
            rules: "required",
          },
          col2: {
            name: "username",
            //colSize: "4",
            colSize: {
              xl: "4",
              lg: "4",
              md: "12",
              sm: "12",
              cols: "12"
            },
            fieldType: "input",
            type: "text",
            vmodel: "serverUsername",
            label: "add_server_username",
            placeholder: "",
            rules: "",
          },
          col3: {
            name: "password",
            //colSize: "4",
            colSize: {
              xl: "4",
              lg: "4",
              md: "12",
              sm: "12",
              cols: "12"
            },
            fieldType: "input",
            type: "text",
            vmodel: "serverPassword",
            label: "add_server_password",
            placeholder: "add_server_password",
            rules: "",
          },
        },
        row2: {
          col1: {
            name: "host",
            //colSize: "4",
            colSize: {
              xl: "4",
              lg: "4",
              md: "12",
              sm: "12",
              cols: "12"
            },
            fieldType: "input",
            type: "text",
            vmodel: "serverHost",
            label: "add_server_host",
            placeholder: "add_server_host",
            rules: "required",
          },
          col2: {
            name: "server type",
            //colSize: "4",
            colSize: {
              xl: "4",
              lg: "4",
              md: "12",
              sm: "12",
              cols: "12"
            },
            fieldType: "select",
            label: "add_server_type",
            vmodel: "serverType",
            value: "id",
            slotOption: "label",
            preRenderFunction: "fetchServerType",
            rules: "required",
          },
          col3: {
            name: "server status",
            //colSize: "4",
            colSize: {
              xl: "4",
              lg: "4",
              md: "12",
              sm: "12",
              cols: "12"
            },
            fieldType: "select",
            label: "add_server_status",
            vmodel: "serverStatus",
            value: "id",
            slotOption: "label",
            preRenderFunction: "fetchStatus",
            rules: "required",
          },
        },
        row3: {
          col1: {
            name: "path",
            //colSize: "6",
            colSize: {
              xl: "6",
              lg: "6",
              md: "6",
              sm: "12",
              cols: "12"
            },
            fieldType: "input",
            type: "text",
            vmodel: "serverPath",
            label: "add_server_path",
            placeholder: "add_server_path",
            rules: "",
          },
          col2: {
            name: "port",
            //colSize: "2",
            colSize: {
              xl: "2",
              lg: "2",
              md: "6",
              sm: "12",
              cols: "12"
            },
            fieldType: "input",
            type: "number",
            vmodel: "serverPort",
            label: "add_server_port",
            placeholder: "add_server_port",
            rules: "",
          },
          // col3: {
          //   name: "public",
          //   colSize: "2",
          //   fieldType: "switch",
          //   vmodel: "serverPublic",
          //   label: "Public Server",
          //   checked: true,
          //   variant: 'success',
          //   iconLeft: 'CheckIcon',
          //   iconRight: 'XIcon',
          // },
        },
        row4: {
          col1: {
            name: "query",
            //colSize: "12",
            colSize: {
              xl: "12",
              lg: "12",
              md: "12",
              sm: "12",
              cols: "12"
            },
            fieldType: "textarea",
            vmodel: "serverQuery",
            label: "add_server_query",
            rows: 6,
            max_rows: 8,
            placeholder: "add_server_query",
            rules: "",
          },
        },
      },
      submitAction: "addServer",
      isActive: false,
    },
    editServerName: {
      modalConfig: {
        title: "edit_server_name",
        type: "edit",
        size: "md",
        centered: true,
        backdropClickDontClose: true,
      },
      repeatForm: false, //if true col size must be max 10 cols per row
      structure: {
        row1: {
          col1: {
            name: "server-name",
            //colSize: "12",
            colSize: {
              xl: "12",
              lg: "12",
              md: "12",
              sm: "12",
              cols: "12"
            },
            fieldType: "input",
            type: "text",
            vmodel: "newServerName",
            // variant: 'secondary',
            label: "edit_server_name",
            placeholder: "edit_server_name",
            rules: "required",
          },
        },
      },
      submitAction: "editServer",
      isActive: false,
    },
    editServerUsername: {
      modalConfig: {
        title: "edit_server_username",
        type: "edit",
        size: "md",
        centered: true,
        backdropClickDontClose: true,
      },
      repeatForm: false, //if true col size must be max 10 cols per row
      structure: {
        row1: {
          col1: {
            name: "server-username",
            //colSize: "12",
            colSize: {
              xl: "12",
              lg: "12",
              md: "12",
              sm: "12",
              cols: "12"
            },
            fieldType: "input",
            type: "text",
            vmodel: "newServerUsername",
            // variant: 'secondary',
            label: "edit_server_username",
            placeholder: "edit_server_username",
            rules: "",
          },
        },
      },
      submitAction: "editServer",
      isActive: false,
    },
    editServerPassword: {
      modalConfig: {
        title: "edit_server_password",
        type: "edit",
        size: "md",
        centered: true,
        backdropClickDontClose: true,
      },
      repeatForm: false, //if true col size must be max 10 cols per row
      structure: {
        row1: {
          col1: {
            name: "server-password",
            //colSize: "12",
            colSize: {
              xl: "12",
              lg: "12",
              md: "12",
              sm: "12",
              cols: "12"
            },
            fieldType: "input",
            type: "text",
            vmodel: "newServerPassword",
            // variant: 'secondary',
            label: "edit_server_password",
            placeholder: "edit_server_password",
            rules: "",
          },
        },
      },
      submitAction: "editServer",
      isActive: false,
    },
    editServerHost: {
      modalConfig: {
        title: "edit_server_host",
        type: "edit",
        size: "md",
        centered: true,
        backdropClickDontClose: true,
      },
      repeatForm: false, //if true col size must be max 10 cols per row
      structure: {
        row1: {
          col1: {
            name: "server-host",
            //colSize: "12",
            colSize: {
              xl: "12",
              lg: "12",
              md: "12",
              sm: "12",
              cols: "12"
            },
            fieldType: "input",
            type: "text",
            vmodel: "newServerHost",
            // variant: 'secondary',
            label: "edit_server_host",
            placeholder: "edit_server_host",
            rules: "required",
          },
        },
      },
      submitAction: "editServer",
      isActive: false,
    },
    editServerEnum_Status: {
      modalConfig: {
        title: "Edit Server Status",
        type: "edit",
        size: "md",
        centered: true,
        backdropClickDontClose: true,
      },
      repeatForm: false, //if true col size must be max 10 cols per row
      structure: {
        row1: {
          col1: {
            name: "server-status",
            //colSize: "12",
            colSize: {
              xl: "12",
              lg: "12",
              md: "12",
              sm: "12",
              cols: "12"
            },
            fieldType: "select",
            label: "Select a Status",
            vmodel: "newServerEnum_Status",
            value: "id",
            slotOption: "label",
            preRenderFunction: "fetchStatus",
            rules: "required",
          },
        },
      },
      submitAction: "editServer",
      isActive: false,
    },
    editServerWeight: {
      modalConfig: {
        title: "Edit Server Weight",
        type: "edit",
        size: "md",
        centered: true,
        backdropClickDontClose: true,
      },
      repeatForm: false, //if true col size must be max 10 cols per row
      structure: {
        row1: {
          col1: {
            name: "server-weight",
            //colSize: "12",
            colSize: {
              xl: "12",
              lg: "12",
              md: "12",
              sm: "12",
              cols: "12"
            },
            fieldType: "input",
            type: "number",
            vmodel: "newServerWeight",
            // variant: 'secondary',
            label: "Server Weight",
            placeholder: "ex: new weight",
            rules: "required",
          },
        },
      },
      submitAction: "editServer",
      isActive: false,
    },
    editServerPort: {
      modalConfig: {
        title: "Edit Server Port",
        type: "edit",
        size: "md",
        centered: true,
        backdropClickDontClose: true,
      },
      repeatForm: false, //if true col size must be max 10 cols per row
      structure: {
        row1: {
          col1: {
            name: "server-port",
            //colSize: "12",
            colSize: {
              xl: "12",
              lg: "12",
              md: "12",
              sm: "12",
              cols: "12"
            },
            fieldType: "input",
            type: "text",
            vmodel: "newServerPort",
            label: "Server Port",
            placeholder: "ex: port",
            rules: "required",
          },
        },
      },
      submitAction: "editServer",
      isActive: false,
    },
    editServerPath: {
      modalConfig: {
        title: "Edit Server Path",
        type: "edit",
        size: "md",
        centered: true,
        backdropClickDontClose: true,
      },
      repeatForm: false, //if true col size must be max 10 cols per row
      structure: {
        row1: {
          col1: {
            name: "server-path",
            //colSize: "12",
            colSize: {
              xl: "12",
              lg: "12",
              md: "12",
              sm: "12",
              cols: "12"
            },
            fieldType: "input",
            type: "text",
            vmodel: "newServerPath",
            label: "Server Path",
            placeholder: "ex: Path/fiqon",
            rules: "required",
          },
        },
      },
      submitAction: "editServer",
      isActive: false,
    },
    editServerQuery: {
      modalConfig: {
        title: "Edit Server Query",
        type: "edit",
        size: "md",
        centered: true,
        backdropClickDontClose: true,
      },
      repeatForm: false, //if true col size must be max 10 cols per row
      structure: {
        row1: {
          col1: {
            name: "query",
            //colSize: "12",
            colSize: {
              xl: "12",
              lg: "12",
              md: "12",
              sm: "12",
              cols: "12"
            },
            fieldType: "textarea",
            vmodel: "newServerQuery",
            label: "Server Query",
            rows: 10,
            placeholder: "Server Query",
            rules: "required",
          },
        },
      },
      submitAction: "editServer",
      isActive: false,
    },
    addOrganizationToServer: {
      modalConfig: {
        title: "Add Organization To Current Server",
        type: "add",
        size: "md",
        centered: true,
        backdropClickDontClose: true,
      },
      repeatForm: false, //if true col size must be max 10 cols per row
      structure: {
        row1: {
          col1: {
            name: "add-org-to-server",
            //colSize: "12",
            colSize: {
              xl: "12",
              lg: "12",
              md: "12",
              sm: "12",
              cols: "12"
            },
            fieldType: "select2",
            vmodel: "orgInfo",
            label: "Search an Organization",
            noOptionLabel: "Type at least 3 letters",
            slotOption: "name",
            onSearch: "getOrganizationByName",
            rules: "required",
          },
        },
      },
      submitAction: "addOrganizationToServer",
      isActive: false,
    },
    //PROFILE
    editUserFullname: {
      modalConfig: {
        title: "Edit User Fullname",
        type: "edit",
        size: "md",
        centered: true,
        backdropClickDontClose: true,
      },
      repeatForm: false, //if true col size must be max 10 cols per row
      structure: {
        row1: {
          col1: {
            name: "user-fullname",
            //colSize: "12",
            colSize: {
              xl: "12",
              lg: "12",
              md: "12",
              sm: "12",
              cols: "12"
            },
            fieldType: "input",
            type: "text",
            vmodel: "newUserFullname",
            // variant: 'secondary',
            label: "User Fullname",
            placeholder: "ex: João Marcos",
            rules: "required",
          },
        },
      },
      submitAction: "editUser",
      isActive: false,
    },
    editUserEmail: {
      modalConfig: {
        title: "Edit User Email",
        type: "edit",
        size: "md",
        centered: true,
        backdropClickDontClose: true,
      },
      repeatForm: false, //if true col size must be max 10 cols per row
      structure: {
        row1: {
          col1: {
            name: "user-email",
            //colSize: "12",
            colSize: {
              xl: "12",
              lg: "12",
              md: "12",
              sm: "12",
              cols: "12"
            },
            fieldType: "input",
            type: "text",
            vmodel: "newUserEmail",
            // variant: 'secondary',
            label: "User Email",
            placeholder: "ex: FiqOnUser@fiqon.com.br",
            rules: "required",
          },
        },
      },
      submitAction: "editUser",
      isActive: false,
    },
    editUserUsername: {
      modalConfig: {
        title: "Edit Username",
        type: "edit",
        size: "md",
        centered: true,
        backdropClickDontClose: true,
      },
      repeatForm: false, //if true col size must be max 10 cols per row
      structure: {
        row1: {
          col1: {
            name: "user-username",
            //colSize: "12",
            colSize: {
              xl: "12",
              lg: "12",
              md: "12",
              sm: "12",
              cols: "12"
            },
            fieldType: "input",
            type: "text",
            vmodel: "newUserUsername",
            // variant: 'secondary',
            label: "Username",
            placeholder: "ex: FiqOnUser",
            rules: "required",
          },
        },
      },
      submitAction: "editUser",
      isActive: false,
    },
    editUserPhone: {
      modalConfig: {
        title: "Edit User Phone",
        type: "edit",
        size: "md",
        centered: true,
        backdropClickDontClose: true,
      },
      repeatForm: false, //if true col size must be max 10 cols per row
      structure: {
        row1: {
          col1: {
            name: "user-phone",
            //colSize: "12",
            colSize: {
              xl: "12",
              lg: "12",
              md: "12",
              sm: "12",
              cols: "12"
            },
            fieldType: "input",
            type: "text",
            vmodel: "newUserPhone",
            // variant: 'secondary',
            label: "Phone",
            placeholder: "",
            rules: "required",
          },
        },
      },
      submitAction: "editUser",
      isActive: false,
    },
    editUserPassword: {
      modalConfig: {
        title: "Edit Password",
        type: "edit",
        size: "md",
        centered: true,
        backdropClickDontClose: true,
      },
      repeatForm: false, //if true col size must be max 10 cols per row
      structure: {
        row1: {
          col1: {
            name: "user-password",
            //colSize: "12",
            colSize: {
              xl: "12",
              lg: "12",
              md: "12",
              sm: "12",
              cols: "12"
            },
            fieldType: "input",
            type: "text",
            vmodel: "newUserPassword",
            // variant: 'secondary',
            label: "Password",
            placeholder: "***********",
            rules: "required",
          },
        },
      },
      submitAction: "editUser",
      isActive: false,
    },
    //PLANS
    addPlan: {
      modalConfig: {
        title: "Add new Plan",
        type: "add",
        size: "md",
        centered: true,
        backdropClickDontClose: true,
      },
      repeatForm: false, //if true col size must be max 10 cols per row
      structure: {
        row1: {
          col1: {
            name: "plan name",
            //colSize: "12",
            colSize: {
              xl: "12",
              lg: "12",
              md: "12",
              sm: "12",
              cols: "12"
            },
            fieldType: "input",
            type: "text",
            vmodel: "newPlanName",
            // variant: 'secondary',
            label: "Plan Name",
            placeholder: "ex: IDK.",
            rules: "required",
          },
          col2: {
            name: "plan category",
            //colSize: "12",
            colSize: {
              xl: "12",
              lg: "12",
              md: "12",
              sm: "12",
              cols: "12"
            },
            fieldType: "select",
            label: "Select a Category",
            vmodel: "planCategory",
            value: "id",
            slotOption: "label",
            preRenderFunction: "fetchPlanCategories",
            rules: "required",
          },
          col3: {
            name: "plan config",
            //colSize: "6",
            colSize: {
              xl: "6",
              lg: "6",
              md: "6",
              sm: "12",
              cols: "12"
            },
            fieldType: "switch",
            vmodel: "newPlanConfig",
            label: "Set Plan Config now?",
            checked: true,
            variant: "success",
            iconLeft: "CheckIcon",
            iconRight: "XIcon",
          },
        },
      },
      submitAction: "addNewPlan",
      isActive: false,
    },
    planConfig: {
      modalConfig: {
        title: "Configure new plan",
        type: "add",
        size: "lg",
        centered: true,
        backdropClickDontClose: true,
      },
      repeatForm: false, //if true col size must be max 10 cols per row
      structure: {
        row1: {
          col1: {
            name: "configuration name",
            //colSize: "6",
            colSize: {
              xl: "6",
              lg: "6",
              md: "6",
              sm: "12",
              cols: "12"
            },
            fieldType: "input",
            vmodel: "configName",
            type: "text",
            label: "Config Name",
            placeholder: "ex: NEW CONFIGGGGG.",
            rules: "required",
          },
        },
        row2: {
          col1: {
            name: "price",
            //colSize: "6",
            colSize: {
              xl: "6",
              lg: "6",
              md: "6",
              sm: "12",
              cols: "12"
            },
            fieldType: "input",
            vmodel: "planPrice",
            type: "number",
            label: "Plan Price",
            placeholder: "Type a Value 💶",
            rules: "required",
          },
          col2: {
            name: "extra price",
            //colSize: "6",
            colSize: {
              xl: "6",
              lg: "6",
              md: "6",
              sm: "12",
              cols: "12"
            },
            fieldType: "input",
            vmodel: "planExtraPrice",
            type: "number",
            label: "Plan Extra Price",
            placeholder: "Type a Value 💶",
            rules: "required",
          },
        },
        row3: {
          col1: {
            name: "limit services",
            //colSize: "4",
            colSize: {
              xl: "4",
              lg: "4",
              md: "12",
              sm: "12",
              cols: "12"
            },
            fieldType: "input",
            vmodel: "servicesLimit",
            type: "number",
            label: "Services Limit",
            placeholder: "ex: 10000",
            rules: "required",
          },
          col2: {
            name: "limit events",
            //colSize: "4",
            colSize: {
              xl: "4",
              lg: "4",
              md: "12",
              sm: "12",
              cols: "12"
            },
            fieldType: "input",
            vmodel: "eventsLimit",
            type: "number",
            label: "Event Limit",
            placeholder: "ex: 5",
            rules: "required",
          },
          col3: {
            name: "requests limit",
            //colSize: "4",
            colSize: {
              xl: "4",
              lg: "4",
              md: "12",
              sm: "12",
              cols: "12"
            },
            fieldType: "input",
            vmodel: "requestLimit",
            type: "number",
            label: "Requests Limit",
            placeholder: "ex: 100000",
            rules: "required",
          },
        },
      },
      submitAction: "setPlanConfig",
      isActive: true,
      maintainActive: true,
    },
    editPlanName: {
      modalConfig: {
        title: "Edit Plan Name",
        type: "edit",
        size: "md",
        centered: true,
        backdropClickDontClose: true,
      },
      repeatForm: false, //if true col size must be max 10 cols per row
      structure: {
        row1: {
          col1: {
            name: "plan name",
            //colSize: "12",
            colSize: {
              xl: "12",
              lg: "12",
              md: "12",
              sm: "12",
              cols: "12"
            },
            fieldType: "input",
            type: "text",
            vmodel: "newPlanName",
            // variant: 'secondary',
            label: "Plan Name",
            placeholder: "ex: IDK.",
            rules: "required",
          },
        },
      },
      submitAction: "editPlan",
      isActive: false,
    },
    editPlanEnum_Category: {
      modalConfig: {
        title: "Edit Plan Category",
        type: "edit",
        size: "md",
        centered: true,
        backdropClickDontClose: true,
      },
      repeatForm: false, //if true col size must be max 10 cols per row
      structure: {
        row1: {
          col1: {
            name: "plan category",
            //colSize: "12",
            colSize: {
              xl: "12",
              lg: "12",
              md: "12",
              sm: "12",
              cols: "12"
            },
            fieldType: "select",
            // variant: 'secondary',
            label: "Select a Category",
            vmodel: "newPlanEnum_Category",
            value: "id",
            slotOption: "label",
            preRenderFunction: "fetchPlanCategories",
            rules: "required",
          },
        },
      },
      submitAction: "editPlan",
      isActive: false,
    },
    editPlanActive: {
      modalConfig: {
        title: "Edit Plan Status",
        type: "edit",
        size: "md",
        centered: true,
        backdropClickDontClose: true,
      },
      repeatForm: false, //if true col size must be max 10 cols per row
      structure: {
        row1: {
          col1: {
            name: "plan status",
            //colSize: "12",
            colSize: {
              xl: "12",
              lg: "12",
              md: "12",
              sm: "12",
              cols: "12"
            },
            fieldType: "select",
            // variant: 'secondary',
            label: "Select a Status",
            vmodel: "newPlanActive",
            value: "value",
            slotOption: "label",
            preRenderFunction: "fetchActiveStatus",
            rules: "required",
          },
        },
      },
      submitAction: "editPlan",
      isActive: false,
    },
    // PLAN CONFIG
    editPlanConfigName: {
      modalConfig: {
        title: "Edit Config Name",
        type: "edit",
        size: "md",
        centered: true,
        backdropClickDontClose: true,
      },
      repeatForm: false, //if true col size must be max 10 cols per row
      structure: {
        row1: {
          col1: {
            name: "name",
            //colSize: "12",
            colSize: {
              xl: "12",
              lg: "12",
              md: "12",
              sm: "12",
              cols: "12"
            },
            fieldType: "input",
            type: "text",
            vmodel: "newPlanConfigName",
            // variant: 'secondary',
            label: "Config Name",
            placeholder: "ex: Request Focused Plan.",
            rules: "required",
          },
        },
      },
      submitAction: "editPlanConfig",
      isActive: false,
    },
    editPlanConfigActive: {
      modalConfig: {
        title: "Edit Config Status",
        type: "edit",
        size: "md",
        centered: true,
        backdropClickDontClose: true,
      },
      repeatForm: false, //if true col size must be max 10 cols per row
      structure: {
        row1: {
          col1: {
            name: "status",
            //colSize: "12",
            colSize: {
              xl: "12",
              lg: "12",
              md: "12",
              sm: "12",
              cols: "12"
            },
            fieldType: "select",
            // variant: 'secondary',
            label: "Select a Status",
            vmodel: "newPlanConfigActive",
            value: "value",
            slotOption: "label",
            preRenderFunction: "fetchActiveStatus",
            rules: "required",
          },
        },
      },
      submitAction: "editPlanConfig",
      isActive: false,
    },
    editPlanConfigMetricsServices: {
      modalConfig: {
        title: "Edit Services Quantity",
        type: "edit",
        size: "md",
        centered: true,
        backdropClickDontClose: true,
      },
      repeatForm: false, //if true col size must be max 10 cols per row
      structure: {
        row1: {
          col1: {
            name: "quantity",
            //colSize: "12",
            colSize: {
              xl: "12",
              lg: "12",
              md: "12",
              sm: "12",
              cols: "12"
            },
            fieldType: "input",
            type: "number",
            vmodel: "newPlanConfigMetricsServices",
            label: "Services",
            placeholder: "Quantity.",
            rules: "required",
          },
        },
      },
      submitAction: "editPlanConfigMetrics",
      isActive: false,
    },
    editPlanConfigMetricsEvents: {
      modalConfig: {
        title: "Edit Events Quantity",
        type: "edit",
        size: "md",
        centered: true,
        backdropClickDontClose: true,
      },
      repeatForm: false, //if true col size must be max 10 cols per row
      structure: {
        row1: {
          col1: {
            name: "name",
            //colSize: "12",
            colSize: {
              xl: "12",
              lg: "12",
              md: "12",
              sm: "12",
              cols: "12"
            },
            fieldType: "input",
            type: "text",
            vmodel: "newPlanConfigMetricsEvents",
            // variant: 'secondary',
            label: "",
            placeholder: "Quantity.",
            rules: "required",
          },
        },
      },
      submitAction: "editPlanConfigMetrics",
      isActive: false,
    },
    editPlanConfigMetricsRequests: {
      modalConfig: {
        title: "Edit Requests Quantity",
        type: "edit",
        size: "md",
        centered: true,
        backdropClickDontClose: true,
      },
      repeatForm: false, //if true col size must be max 10 cols per row
      structure: {
        row1: {
          col1: {
            name: "name",
            //colSize: "12",
            colSize: {
              xl: "12",
              lg: "12",
              md: "12",
              sm: "12",
              cols: "12"
            },
            fieldType: "input",
            type: "text",
            vmodel: "newPlanConfigMetricsRequests",
            // variant: 'secondary',
            label: "Config Name",
            placeholder: "Quantity.",
            rules: "required",
          },
        },
      },
      submitAction: "editPlanConfigMetrics",
      isActive: false,
    },
    addOrganizationToPlanVisibility: {
      modalConfig: {
        title: "Add Organization To Plan Visibity",
        type: "add",
        size: "md",
        centered: true,
        backdropClickDontClose: true,
      },
      repeatForm: false, //if true col size must be max 10 cols per row
      structure: {
        row1: {
          col1: {
            name: "organization",
            //colSize: "12",
            colSize: {
              xl: "12",
              lg: "12",
              md: "12",
              sm: "12",
              cols: "12"
            },
            fieldType: "select2",
            vmodel: "org",
            // variant: 'secondary',
            label: "Search an Organization",
            noOptionLabel: "Type at least 3 letters",
            slotOption: "name",
            onSearch: "getOrganizationByName",
            rules: "required",
          },
        },
      },
      submitAction: "addOrganizationToPlanVisibility",
      isActive: false,
    },
    //SERVICES
    addService: {
      modalConfig: {
        title: "add_service",
        type: "add",
        size: "md",
        centered: true,
        backdropClickDontClose: true,
      },
      repeatForm: false, //if true col size must be max 10 cols per row
      structure: {
        row1: {
          col1: {
            name: "identifier",
            //colSize: "12",
            colSize: {
              xl: "12",
              lg: "12",
              md: "12",
              sm: "12",
              cols: "12"
            },
            fieldType: "input",
            type: "text",
            vmodel: "identifier",
            // variant: 'secondary',
            label: "add_service_identifier",
            placeholder: "add_service_identifier",
            rules: "required",
          },
          col2: {
            name: "base path",
            //colSize: "12",
            colSize: {
              xl: "12",
              lg: "12",
              md: "12",
              sm: "12",
              cols: "12"
            },
            fieldType: "input",
            type: "text",
            vmodel: "basePath",
            // variant: 'secondary',
            label: "add_service_base_path",
            placeholder: "add_service_base_path",
            rules: "required",
          },
        },
      },
      submitAction: "addService",
      isActive: false,
    },
    addServicePermission: {
      modalConfig: {
        title: "add_service_permission",
        type: "add",
        size: "md",
        centered: true,
        backdropClickDontClose: true,
      },
      repeatForm: false, //if true col size must be max 10 cols per row
      structure: {
        row1: {
          col1: {
            name: "event",
            //colSize: "12",
            colSize: {
              xl: "12",
              lg: "12",
              md: "12",
              sm: "12",
              cols: "12"
            },
            fieldType: "select",
            vmodel: "event",
            label: "Select an Event",
            value: 'id',
            slotOption: "event_identifier",
            preRenderFunction: "getEventsByTransmissionGroup",
            rules: "required",
          },
        },
      },
      submitAction: "addServicePermission",
      isActive: false,
    },
    editServiceIdentifier: {
      modalConfig: {
        title: "Edit Identifier",
        type: "edit",
        size: "md",
        centered: true,
        backdropClickDontClose: true,
      },
      repeatForm: false, //if true col size must be max 10 cols per row
      structure: {
        row1: {
          col1: {
            name: "identifier",
            //colSize: "12",
            colSize: {
              xl: "12",
              lg: "12",
              md: "12",
              sm: "12",
              cols: "12"
            },
            fieldType: "input",
            type: "text",
            vmodel: "newServiceIdentifier",
            // variant: 'secondary',
            label: "Service Identifier",
            placeholder: "ex: FiqOn.",
            rules: "required",
          },
        },
      },
      submitAction: "editService",
      isActive: false,
    },
    editServiceBase_Path: {
      modalConfig: {
        title: "Edit Base Path",
        type: "edit",
        size: "md",
        centered: true,
        backdropClickDontClose: true,
      },
      repeatForm: false, //if true col size must be max 10 cols per row
      structure: {
        row1: {
          col1: {
            name: "base path",
            //colSize: "12",
            colSize: {
              xl: "12",
              lg: "12",
              md: "12",
              sm: "12",
              cols: "12"
            },
            fieldType: "input",
            type: "text",
            vmodel: "newServiceBase_Path",
            // variant: 'secondary',
            label: "Service Base Path",
            placeholder: "ex: fiqon.jooj.br.",
            rules: "required",
          },
        },
      },
      submitAction: "editService",
      isActive: false,
    },
    editServiceBase_Path: {
      modalConfig: {
        title: "Edit Base Path",
        type: "edit",
        size: "md",
        centered: true,
        backdropClickDontClose: true,
      },
      repeatForm: false, //if true col size must be max 10 cols per row
      structure: {
        row1: {
          col1: {
            name: "base path",
            //colSize: "12",
            colSize: {
              xl: "12",
              lg: "12",
              md: "12",
              sm: "12",
              cols: "12"
            },
            fieldType: "input",
            type: "text",
            vmodel: "newServiceBase_Path",
            // variant: 'secondary',
            label: "Service Base Path",
            placeholder: "ex: fiqon.jooj.br.",
            rules: "required",
          },
        },
      },
      submitAction: "editService",
      isActive: false,
    },
    addServiceWebhook:{
      modalConfig: {
        title: "add_service_webhook",
        type: "add",
        size: "md",
        centered: true,
        backdropClickDontClose: true,
      },
      repeatForm: false, //if true col size must be max 10 cols per row
      structure: {
        row1: {
          col1: {
            name: "identifier",
            colSize: "12",
            fieldType: "input",
            type: "text",
            vmodel: "newWebhookIdentifier",
            // variant: 'secondary',
            label: "webhook_identifier",
            placeholder: "webhook_identifier",
            rules: "required",
          },
        },
      },
      submitAction: "addServiceWebhook",
      isActive: false,
    },
    //EVENT
    addEvent: {
      modalConfig: {
        title: "add_event",
        type: "add",
        size: "lg",
        centered: true,
        backdropClickDontClose: true,
      },
      repeatForm: false, //if true col size must be max 10 cols per row
      structure: {
        row1: {
          col1: {
            name: "identifier",
            //colSize: "6",
            colSize: {
              xl: "6",
              lg: "6",
              md: "6",
              sm: "12",
              cols: "12"
            },
            fieldType: "input",
            type: "text",
            vmodel: "eventIdentifier",
            // variant: 'secondary',
            label: "add_event",
            placeholder: "add_event",
            rules: "required",
          },
          /* col2: {
            name: "description",
            //colSize: "6",
            colSize: {
              xl: "6",
              lg: "6",
              md: "6",
              sm: "12",
              cols: "12"
            },
            fieldType: "textarea",
            vmodel: "description",
            label: "Event Description",
            rows: 2,
            max_rows: 4,
            placeholder: "Inform a description",
            rules: 'required',
          }, */
        },
        row2:{
          col1: {
            name: "retry limite",
            //colSize: "4",
            colSize: {
              xl: "6",
              lg: "6",
              md: "6",
              sm: "12",
              cols: "12"
            },
            fieldType: "specificSelect",
            // variant: 'secondary',
            label: "retry_limit",
            vmodel: "retryLimit",
            value: "id",
            slotOption: "label",
            preRenderFunction: "fetchEnumRetry",
            rules: 'required',
          },
          col2:{
            name: "max retry",
            //colSize: "3",
            colSize: {
              xl: "6",
              lg: "6",
              md: "6",
              sm: "12",
              cols: "12"
            },
            fieldType: "specificInput",
            type: "number",
            vmodel: "max_retry",
            // variant: 'secondary',
            label: "max_retry",
            placeholder: "max_retry",
            rules: "required",
          }
        },  
        row3:{
          col1: {
            name: "json object",
            //colSize: "12",
            colSize: {
              xl: "12",
              lg: "12",
              md: "12",
              sm: "12",
              cols: "12"
            },
            fieldType: "textarea",
            vmodel: "object",
            label: "json_object",
            rows: 4,
            max_rows: 1000,
            placeholder: "json_object",
            rules: 'required',
          },
        },
      },
      submitAction: "addEvent",
      isActive: false,
    },
    addEventPermission: {
      modalConfig: {
        title: "Add Event Permission",
        type: "add",
        size: "md",
        centered: true,
        backdropClickDontClose: true,
      },
      repeatForm: false, //if true col size must be max 10 cols per row
      structure: {
        row1: {
          col1: {
            name: "service",
            //colSize: "12",
            colSize: {
              xl: "12",
              lg: "12",
              md: "12",
              sm: "12",
              cols: "12"
            },
            fieldType: "select",
            vmodel: "service",
            label: "Select an Service",
            slotOption: "identifier",
            rules: "required",
            value: "id",
            preRenderFunction: "getServicesByTransmissionGroup",
          },
        },
      },
      submitAction: "addEventPermission",
      isActive: false,
    },
    editEventEvent_Identifier: {
      modalConfig: {
        title: "Edit Event Identifier",
        type: "edit",
        size: "md",
        centered: true,
        backdropClickDontClose: true,
      },
      repeatForm: false, //if true col size must be max 10 cols per row
      structure: {
        row1: {
          col1: {
            name: "identifier",
            //colSize: "12",
            colSize: {
              xl: "12",
              lg: "12",
              md: "12",
              sm: "12",
              cols: "12"
            },
            fieldType: "input",
            type: "text",
            vmodel: "newEventEvent_Identifier",
            // variant: 'secondary',
            label: "Event Identifier",
            placeholder: "ex: FiqOn.",
            rules: "required",
          },
        },
      },
      submitAction: "editEvent",
      isActive: false,
    },
    editEventDescription: {
      modalConfig: {
        title: "Edit Event Description",
        type: "edit",
        size: "md",
        centered: true,
        backdropClickDontClose: true,
      },
      repeatForm: false, //if true col size must be max 10 cols per row
      structure: {
        row1: {
          col1: {
            name: "description",
            //colSize: "12",
            colSize: {
              xl: "12",
              lg: "12",
              md: "12",
              sm: "12",
              cols: "12"
            },
            fieldType: "textarea",
            vmodel: "newEventDescription",
            label: "Event Description",
            rows: 4,
            max_rows: 8,
            placeholder: "Inform a description",
            rules: "required",
          },
        },
      },
      submitAction: "editEvent",
      isActive: false,
    },
    editEventEnum_Retry: {
      modalConfig: {
        title: "Edit Retry",
        type: "add",
        size: "md",
        centered: true,
        backdropClickDontClose: true,
      },
      repeatForm: false, //if true col size must be max 10 cols per row
      structure: {
        row1: {
          col1: {
            name: "retry type",
            //colSize: "12",
            colSize: {
              xl: "12",
              lg: "12",
              md: "12",
              sm: "12",
              cols: "12"
            },
            fieldType: "select",
            label: "Select a Retry Type",
            vmodel: "newEventEnum_Retry",
            value: "id",
            slotOption: "label",
            preRenderFunction: "fetchEnumRetry",
            rules: "required",
          },
        },
      },
      submitAction: "editEventEnumRetry",
      isActive: false,
    },
    // EVENT OBJECT FIELDS --------------------------------
    detectObjectFieldsFromJSON: {
      modalConfig: {
        title: "Edit Retry",
        type: "add",
        size: "lg",
        centered: true,
        backdropClickDontClose: true,
      },
      repeatForm: false,
      structure: {
        row1: {
          col1: {
            name: "JSON",
            //colSize: "12",
            colSize: {
              xl: "12",
              lg: "12",
              md: "12",
              sm: "12",
              cols: "12"
            },
            fieldType: "textarea",
            vmodel: "jsonObject",
            label: "Detect JSON Fields",
            rows: 6,
            max_rows: 30,
            placeholder: "JSON HERE",
            rules: "required",
          },
        },
      },
      submitAction: "detectObjectFieldsFromJSON",
      isActive: false,
    },
    // WEBHOOK --------------------
    editWebhookIdentifier: {
      modalConfig: {
        title: "Edit Identifier",
        type: "edit",
        size: "md",
        centered: true,
        backdropClickDontClose: true,
      },
      repeatForm: false, //if true col size must be max 10 cols per row
      structure: {
        row1: {
          col1: {
            name: "identifier",
            colSize: "12",
            fieldType: "input",
            type: "text",
            vmodel: "newWebhookIdentifier",
            // variant: 'secondary',
            label: "webhook_identifier",
            placeholder: "webhook_identifier",
            rules: "required",
          },
        },
      },
      submitAction: "editWebhook",
      isActive: false,
    },
    editWebhookEnum_Status: {
      modalConfig: {
        title: "Edit Status",
        type: "add",
        size: "md",
        centered: true,
        backdropClickDontClose: true,
      },
      repeatForm: false, //if true col size must be max 10 cols per row
      structure: {
        row1: {
          col1: {
            name: "status",
            colSize: "12",
            fieldType: "select",
            label: "Select Status",
            vmodel: "newWebhookEnum_Status",
            value: "id",
            slotOption: "label",
            preRenderFunction: "fetchEnumStatus",
            rules: "required",
          },
        },
      },
      //submitAction: "editWebhookStatus",
      submitAction: "editWebhook",
      isActive: false,
    },
    // GROUP
    editTransmissionName:{
      modalConfig: {
        title: "edit_group_name",
        type: "edit",
        size: "md",
        centered: true,
        backdropClickDontClose: true,
      },
      repeatForm: false, //if true col size must be max 10 cols per row
      structure: {
        row1: {
          col1: {
            name: "group-name",
            //colSize: "12",
            colSize: {
              xl: "12",
              lg: "12",
              md: "12",
              sm: "12",
              cols: "12"
            },
            fieldType: "input",
            type: "text",
            vmodel: "newGroupName",
            // variant: 'secondary',
            label: "group_name",
            placeholder: "ex: FiqOnGroup.",
            rules: "required",
          },
        },
      },
      submitAction: "editTransmission",
      isActive: false,
    },
    editTransmissionActive:{
      modalConfig: {
        title: "edit_group_status",
        type: "edit",
        size: "md",
        centered: true,
        backdropClickDontClose: true,
      },
      repeatForm: false, //if true col size must be max 10 cols per row
      structure: {
        row1: {
          col1: {
            name: "group-status",
            //colSize: "12",
            colSize: {
              xl: "12",
              lg: "12",
              md: "12",
              sm: "12",
              cols: "12"
            },
            fieldType: "switch",
            type: "switch",
            vmodel: "newGroupActive",
            // variant: 'secondary',
            label: "group_status",
            placeholder: "ex: FiqOnGroup.",
            rules: "required",
          },
        },
      },
      submitAction: "editTransmission",
      isActive: false,
    },
    editTransmissionDescription:{
      modalConfig: {
        title: "edit_group_description",
        type: "edit",
        size: "md",
        centered: true,
        backdropClickDontClose: true,
      },
      repeatForm: false, //if true col size must be max 10 cols per row
      structure: {
        row1: {
          col1: {
            name: "group-description",
            //colSize: "12",
            colSize: {
              xl: "12",
              lg: "12",
              md: "12",
              sm: "12",
              cols: "12"
            },
            fieldType: "textarea",
            type: "textarea",
            vmodel: "newGroupDescription",
            // variant: 'secondary',
            label: "group_description",
            placeholder: "edit_description",
            rules: "required",
          },
        },
      },
      submitAction: "editTransmission",
      isActive: false,
    },
  });
  