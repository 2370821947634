import ReleaseTags from '@/custom/class/Enum/ReleaseTags'

export default {
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    getReleaseTags() {
      let tags = new ReleaseTags()
      return tags.items
    }
  }
};