import { orgAccessLevel } from "@/custom/roles";

export const customTableActions = Object.freeze({
  planIsPrivate:{
    type: "confirm",
    title: "Change Privacy",
    action: "togglePlanIsPrivate",
    // authorized: orgAccessLevel.owner,
  }
})

export const tableActions = Object.freeze({
  organization: {
    "first-slot": {
      type: "add",
      title: "Add an User",
      action: "addUserToOrganization",
      icon: "PlusIcon",
      color: "text-primary",
      variantColor: "outline-primary",
      authorized: orgAccessLevel.owner,
    },
    "second-slot": {
      type: "edit",
      title: "Edit Organization",
      action: "editOrganizationById",
      icon: "EditIcon",
      color: "text-warning",
      variantColor: "outline-warning",
      authorized: orgAccessLevel.admin,
    },
    "third-slot": {
      type: "newPage",
      title: "Organization Info",
      action: "infoOrgById",
      icon: "InfoIcon",
      color: "text-info",
      variantColor: "outline-info",
      authorized: orgAccessLevel.developer,
    },
    "forth-slot": {
      type: "delete",
      title: "Delete Organization",
      action: "deleteOrgById",
      icon: "TrashIcon",
      color: "text-danger",
      variantColor: "outline-danger",
      authorized: orgAccessLevel.owner,
    },
    item: "SET_CURRENT_ORG",
  },
  orgUsers: {
    "first-slot": {
      type: "edit",
      title: "Edit User Role",
      action: "editOrganizationUser",
      icon: "EditIcon",
      color: "text-warning",
      variantColor: "outline-warning",
      authorized: orgAccessLevel.admin,
    },
    "second-slot": {
      type: "delete",
      title: "Delete User from Organization",
      action: "deleteUserFromOrgById", //ok
      icon: "TrashIcon",
      color: "text-danger",
      variantColor: "outline-danger",
      authorized: orgAccessLevel.owner,
    },
    item: "SET_CURRENT_ORG_USER",
  },
  servers: {
    "first-slot": {
      type: "newPage",
      title: "Server Info",
      action: "infoServerById",
      icon: "InfoIcon",
      color: "text-info",
      variantColor: "outline-info",
      authorized: orgAccessLevel.admin,
    },
    "second-slot": {
      type: "delete",
      title: "Delete Server",
      action: "deleteServerById",
      icon: "TrashIcon",
      color: "text-danger",
      variantColor: "outline-danger",
      authorized: orgAccessLevel.admin,
    },
    item: "SET_CURRENT_SERVER",
  },
  plans: {
    "first-slot": {
      type: "newPage",
      title: "Plan Info",
      action: "infoPlanById",
      icon: "InfoIcon",
      color: "text-info",
      variantColor: "outline-info",
    },
    "second-slot": {
      type: "delete",
      title: "Delete Plan",
      action: "deletePlanById",
      icon: "TrashIcon",
      color: "text-danger",
      variantColor: "outline-danger",
    },
    item: "SET_CURRENT_PLAN",
  },
  planConfig: {
    "first-slot": {
      type: "delete",
      title: "Clone this Configuration",
      action: "clonePlanById",
      icon: "CopyIcon",
      color: "text-success",
      variantColor: "outline-success",
    },
    "second-slot": {
      type: "newPage",
      title: "Plan Config Info",
      action: "infoPlanConfigById",
      icon: "ExternalLinkIcon",
      color: "text-info",
      variantColor: "outline-info",
    },
    "third-slot": {
      type: "delete",
      title: "Delete Plan Config",
      action: "deletePlanConfigById",
      icon: "TrashIcon",
      color: "text-danger",
      variantColor: "outline-danger",
    },
    item: "SET_CURRENT_PLAN_CONFIG",
  },
  planVisibility: {
    "first-slot": {
      type: "delete",
      title: "Delete Organization from Plan Visibility",
      action: "deleteOrgFromPlanById",
      icon: "TrashIcon",
      color: "text-danger",
      variantColor: "outline-danger",
      authorized: orgAccessLevel.owner,
    },
    item: "SET_CURRENT_ORGANIZATION_PLAN",
  },
  IPWhitelist: {
    "first-slot":{
      type: "delete",
      title: "Delete IP from whitelist",
      action: "deleteIPById",
      icon: "TrashIcon",
      color: "text-danger",
      variantColor: "outline-danger",
    },
    item: "SET_CURRENT_WHITELIST_IP",
  },
  userSessions: {
    "first-slot":{
      type: "delete",
      title: "Delete Session",
      action: "deleteSession",
      icon: "TrashIcon",
      color: "text-danger",
      variantColor: "outline-danger",
    },
    item: "SET_CURRENT_MANAGE_SESSION",
  },
  session: {
    "first-slot":{
      type: "confirm",
      title: "Embed Session",
      action: "embedUserSession",
      icon: "AnchorIcon",
      color: "text-primary",
      variantColor: "outline-primary",
    },
    "second-slot": {
      type: "delete",
      title: "Delete User Session",
      action: "deleteSession",
      icon: "TrashIcon",
      color: "text-danger",
      variantColor: "outline-danger",
     
    },
    item: "SET_CURRENT_MANAGE_SESSION",
  },
  ip_whitelist: {
    "first-slot":{
      type: "delete",
      title: "Delete IP from whitelist",
      action: "deleteIPById",
      icon: "TrashIcon",
      color: "text-danger",
      variantColor: "outline-danger",
    },
    item: "SET_CURRENT_WHITELIST_IP",
  },

  servicesByTransmissionGroup: {
    "first-slot":{
      type: "newPage",
      title: "Service Info",
      action: "serviceInfo",
      icon: "InfoIcon",
      color: "text-info",
      variantColor: "outline-info",
    },
    "second-slot":{
      type: "delete",
      title: "Delete Service",
      action: "deleteServiceById",
      icon: "TrashIcon",
      color: "text-danger",
      variantColor: "outline-danger",
    },
    item: "SET_CURRENT_SERVICE",
  },
  eventsByTransmissionGroup: {
    "first-slot":{
      type: "newPage",
      title: "Event Info",
      action: "eventInfo",
      icon: "InfoIcon",
      color: "text-info",
      variantColor: "outline-info",
    },
    "second-slot":{
      type: "delete",
      title: "Delete Event",
      action: "deleteEventById",
      icon: "TrashIcon",
      color: "text-danger",
      variantColor: "outline-danger",
    },
    item: "SET_CURRENT_EVENT",
  },
  serviceEvents: {
    "first-slot":{
      type: "delete",
      title: "Delete Service",
      action: "deleteServicePermission",
      icon: "TrashIcon",
      color: "text-danger",
      variantColor: "outline-danger",
    },
    item: "SET_CURRENT_SERVICE_PERMISSION",
  },
  eventServices: {
    "first-slot":{
      type: "delete",
      title: "Delete Event",
      action: "deleteEventPermission",
      icon: "TrashIcon",
      color: "text-danger",
      variantColor: "outline-danger",
    },
    item: "SET_CURRENT_EVENT_PERMISSION",
  },
  compiledScripts: {
    "first-slot":{
      type: "delete",
      title: "compiled.table.delete.title",
      action: "deleteCompiledScript",
      icon: "TrashIcon",
      color: "text-danger",
      variantColor: "outline-danger",
    },
    "second-slot":{
      type: "delete",
      title: "compiled.table.revert.title",
      action: "revertCompiledScript",
      icon: "SkipBackIcon",
      color: "text-warning",
      variantColor: "outline-warning",
    },
    // "third-slot":{
    //   type: "exec",
    //   title: "compiled.table.view.title",
    //   action: (item) => {
        
    //   },
    //   icon: "EyeIcon",
    //   color: "text-info",
    //   variantColor: "outline-info",
    // },
    item: "SET_CURRENT_COMPILED_SCRIPT",
  },
  serviceWebhook: {
    "first-slot":{
      type: "newPage",
      title: "Webhook Info",
      action: "webhookInfo",
      icon: "InfoIcon",
      color: "text-info",
      variantColor: "outline-info",
    },
    "second-slot":{
      type: "delete",
      title: "Delete Webhook",
      action: "deleteWebhookById",
      icon: "TrashIcon",
      color: "text-danger",
      variantColor: "outline-danger",
    },
    item: "SET_CURRENT_SERVICE_WEBHOOK",
  }
});
