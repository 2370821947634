export default [
  {
    path: "/servers",
    name: "servers",
    component: () => import("@/views/pages/server/Servers.vue"),
    meta: {
      action: "manage",
      title: "FiqOn - Servers",
      refreshTable: true,
      pageTitle: "Server",
      breadcrumb: [
        {
          text: "Servers",
          active: true,
        },
      ],
    },
  },
  {
    path: "/servers/info-server/:serverId",
    name: "info-server",
    component: () => import("@/views/pages/server/InfoServer.vue"),
    meta: {
      action: "manage",
      title: "FiqOn - Server Info",
      pageTitle: "Server Info",
      refreshTable: true,
      refreshInput: true,
      breadcrumb: [
        {
          text: "Server",
          to: { name: "servers" },
        },
        {
          text: "Server Info",
          active: true,
        },
      ],
    },
  },
  {
    path: "/servers/info-server/edit-server/:serverId",
    name: "edit-server",
    component: () => import("@/views/pages/server/EditServer.vue"),
    meta: {
      action: "manage",
      title: "FiqOn - Edit Server",
      pageTitle: "Edit Server",
      refreshTable: true,
      breadcrumb: [
        {
          text: "Server",
          to: { name: "servers" },
        },
        {
          text: "Server Info",
          to: { name: "info-server" },
        },
        {
          text: "Edit Server",
          active: true,
        },
      ],
    },
  },
];
