import axiosIns from "@/libs/axios";
import moment from "moment";
import router from '@/router'
import { makeToast } from "@/layouts/components/Popups";
import i18n from '@/libs/i18n'

export default {
  state: {
    currentServer: null,
    serverAction: null,
  },
  getters: {
    getCurrentServer: (state) => {
      return state.currentServer;
    },
    getServerAction: (state) => {
      return state.serverAction;
    },
  },
  mutations: {
    SET_CURRENT_SERVER(state, data) {
      state.currentServer = data
    },
    SET_SERVER_ACTION(state, data) {
      state.serverAction = data
    },
  },
  actions: {
    setServerAction({ commit }, state) {
      commit('SET_SERVER_ACTION', state)
      return
    },
    async getAllServers({ commit, dispatch }, data = null) {
      commit('SET_IS_BTABLE_BUSY', true)
      let config = {
        headers: {
          "x-api-url": await dispatch('resolveFilterUrl', {url: `/central/server`, filters: data }),
          session: `Session ${localStorage.getItem("sessionKey")}`,
        },
      };
      return new Promise((resolve, reject) => {
       console.trace(); axiosIns
          .get("/", config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {

              let serversInfo = [];
              let total_items = resp.data.data.paginator.total_items

              resp.data.data.items.map(function(value, key){
                serversInfo.push(value)
                serversInfo[key].created_at = (moment(value.created_at).format('LLL'))
                serversInfo[key].status = (value.enum_status.label)
                serversInfo[key].type = (value.enum_type.label)
              })    

              serversInfo['totalItems'] = total_items

              resolve(serversInfo);
            } else {
              throw resp
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error);
          });
      });
    },
    addServer(_ , payload) {
      let postData = {
        data: {
          enum_type     : payload.data.serverType,
          enum_status   : payload.data.serverStatus,
          host          : payload.data.serverHost,
          name          : payload.data.serverName,
          password      : payload.data.serverPassword ? payload.data.serverPassword : null,
          path          : payload.data.serverPath     ? payload.data.serverPath     : null,
          port          : payload.data.serverPort     ? parseInt(payload.data.serverPort)     : null,
          query_param   : payload.data.serverQuery    ? payload.data.serverQuery    : null,
          username      : payload.data.serverUsername ? payload.data.serverUsername : null,
        },
      };
      let config = {
        headers: {
          "x-api-url": "/central/server",
          session: `Session ${localStorage.getItem("sessionKey")}`,
        },
      };
      return new Promise((resolve, reject) => {
       console.trace(); axiosIns
          .post("/", postData, config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {
              resolve(resp.data);
            } else {
              throw resp
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error);
          });
      });
    },
    async fetchServerType({ dispatch }, data = null) {
      let config = {
        headers: {
          "x-api-url": await dispatch("resolveUrl", { url: `/enum/server/type`, params: data }),
          session: `Session ${localStorage.getItem("sessionKey")}`,
        },
      };
      return new Promise((resolve, reject) => {
       console.trace(); axiosIns
          .get("/", config)
          .then((resp) => {
            console.log(resp)
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {
              resolve(resp.data.data);
            } else {
              throw resp
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error);
          });
      });
    },
    deleteServerById(_ , payload) {
      let config = {
        headers: {
          "x-api-url": `/central/server/${payload.item.id}`,
          session: `Session ${localStorage.getItem("sessionKey")}`,
        },
      };
      return new Promise((resolve, reject) => {
       console.trace(); axiosIns
          .delete("/", config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {
              resolve(resp.data.data);
            } else {
              throw resp
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error);
          });
      });
    },
    infoServerById(_) {

      let server = this.getters.getCurrentServer.id;
      router.push({ name: 'info-server', params: { serverId: server }})

    },   
    getServerById(_ , params) {
      let config = {
        headers: {
          "x-api-url": `/central/server/${params.serverId}`,
          session: `Session ${localStorage.getItem("sessionKey")}`,
        },
      };
      return new Promise((resolve, reject) => {
       console.trace(); axiosIns
          .get("/", config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {

              let serversInfo = []; //
              let value = resp.data.data //

              serversInfo = value
              serversInfo.created_at = (moment(value.created_at).format('LLL'))
              serversInfo.status = (value.enum_status.label)
              serversInfo.type = (value.enum_type.label)


              resolve(serversInfo);
            } else {
              throw resp
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error);
          });
      });
    },
    editServer(_ , payload) {
      let serverId    = this.getters.getCurrentServer.id
      let action      = this.getters.getServerAction;
      let lowerAction = action.toLowerCase();
      
      let config = {
        headers: {
          "x-api-url": `/central/server/${serverId}/${lowerAction}`,
          session: `Session ${localStorage.getItem("sessionKey")}`,
        },
      };
      let postData = {
        data: {
          new_value: (action === "Weight" || action === "Port") ? parseInt(payload.data[`newServer${action}`]) : payload.data[`newServer${action}`]
        },
      };
      return new Promise((resolve, reject) => {
       console.trace(); axiosIns
          .patch("/", postData, config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {
              resolve(resp);
            } else {
              throw resp
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error);
          });
      });
    },
    editIsServerPublic(_, payload) {
      let serverId = this.getters.getCurrentServer.id

      let config = {
        headers: {
          "x-api-url": `/central/server/${serverId}/is_public`,
          session: `Session ${localStorage.getItem("sessionKey")}`,
        },
      };
      let postData = {
        data: {
          new_value: payload
        },
      };
      return new Promise((resolve, reject) => {
       console.trace(); axiosIns
          .patch("/", postData, config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {
              resolve(resp);
            } else {
              throw resp
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error);
          });
      });
    },
    updateServer(_ , data) {
      let serverId = data.id;
      
      let config = {
        headers: {
          "x-api-url": `/central/server/${serverId}`,
          session: `Session ${localStorage.getItem("sessionKey")}`,
        },
      };
      let postData = {
        data: {
          enum_status   : data.status,
          host          : data.host,
          is_public     : data.is_public,
          name          : data.name,
          weight        : parseInt(data.weight),
          password      : data.password     ? data.password       : null,
          username      : data.username     ? data.username       : null,
          path          : data.path         ? data.path           : null,
          port          : data.port         ? parseInt(data.port) : null,
          query_param   : data.query_param  ? data.query_param    : null,
        },
      };
      return new Promise((resolve, reject) => {
       console.trace(); axiosIns
          .put("/", postData, config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {
              resolve(resp);
            } else {
              throw resp
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error);
          });
      });
    },
    addOrganizationToServer(_ , payload){
      let serverId = this.getters.getCurrentServer.id
      
      let config = {
        headers: {
          "x-api-url": '/central/server_permission',
          session: `Session ${localStorage.getItem("sessionKey")}`,
        },
      };
      let postData = {
        data: {
          "organization": payload.data.orgInfo.id,
          "server": serverId
        },
      };
      return new Promise((resolve, reject) => {
       console.trace(); axiosIns
          .post("/", postData, config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {
              resolve(resp);
            } else {
              throw resp
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error);
          });
      });
    },
    getServerByName({ commit }, data) {
      let config = {
        headers: {
          "x-api-url": `/central/server/by/name?value=${data}`,
          session: `Session ${localStorage.getItem("sessionKey")}`,
        },
      };
      return new Promise((resolve, reject) => {
       console.trace(); axiosIns
          .get("/", config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {
              
              let serversInfo = [];
              
              if(resp.data.data.items !== []){
                resp.data.data.items.map(function(value, key){
                  serversInfo.push(value)
                  serversInfo[key].created_at = (moment(value.created_at).format('LLL'))
                  serversInfo[key].status = (value.enum_status.label)
                  serversInfo[key].type = (value.enum_type.label)
                })    
              }else{
                return null
              }

              resolve(serversInfo);
            } else {
              throw resp
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error);
          });
      });
    },
    fetchServerByType({ commit }, data) {
      let config = {
        headers: {
          "x-api-url": `/central/server/by/enum_type?value=${data}`,
          session: `Session ${localStorage.getItem("sessionKey")}`,
        },
      };
      return new Promise((resolve, reject) => {
       console.trace(); axiosIns
          .get("/", config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {
              
              let serversInfo = []; //
              
              if(resp.data.data.items !== []){
                resp.data.data.items.map(function(value, key){
                  serversInfo.push(value)
                  serversInfo[key].created_at = (moment(value.created_at).format('LLL'))
                  serversInfo[key].status = (value.enum_status.label)
                  serversInfo[key].type = (value.enum_type.label)
                })    
              }else{
                return null
              }

              resolve(serversInfo);
            } else {
              throw resp
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error);
          });
      });
    },
  },
};
