export default [
  {
    path: '/transmission/:transmissionID/events',
    name: 'events',
    component: () =>
        import ('@/views/pages/events/Events.vue'),
    meta: {
      title: "FiqOn - Events",
      refreshTable: true,
      pageTitle: "Events",
      breadcrumb: [
        {
					text: 'Transmission Dashboard',
					to: { name: 'transmission-dashboard' }
				},
        {
          text: "Events",
          active: true,
        },
      ],
			loadTransmissionGroup: true,
			showTerminal: false,
    },
  },
  {
    path: '/transmission/:transmissionID/events/event-info/:eventId',
    name: 'event-info',
    component: () =>
        import ('@/views/pages/events/EventInfo.vue'),
    meta: {
      title: "FiqOn - Events",
      refreshTable: true,
      refreshInput: true,
      pageTitle: "Events",
      breadcrumb: [
        {
          text: "Events",
          to: { name : "events" }
        },
        {
          text: "Event Info",
          active: true,
        },
      ],
			loadTransmissionGroup: true,
			showTerminal: false,
    },
  },
]