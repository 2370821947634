import custom from "@/custom";

export default {
  state: {
    baseModalObject: [],
  },
  getters: {
    getBaseModalObj: (state) => {
      return state.baseModalObject;
    },
  },
  mutations: {
    SET_BASE_MODAL_OBJECT(state, baseModalObject) {
      state.baseModalObject = baseModalObject;
    },
    SET_IS_ACTIVE(state, modalState) {
      switch (true) {
        case state.baseModalObject.isActive == modalState: {
          modalState ? state.baseModalObject.isActive += 1 : '' ;
          break;
        }
        case modalState: {
          state.baseModalObject.isActive = true;
          break;
        }
        default: {
          state.baseModalObject.isActive = false;
          break;
        }
      }
    },
  },
  actions: {
    setModalObj({ commit }, modalType) {
      commit("SET_BASE_MODAL_OBJECT", custom.modalStructure[modalType]);
    },
    setActiveModal({ commit }, modalState) {
      commit("SET_IS_ACTIVE", modalState);
    },
  },
};
