export const confirmModalStructure = Object.freeze({
    deleteOrgById: {
      message: "Are you sure you want to delete ",
      config: {
        title: "Delete Organization",
        okTitle: "Confirm",
        okVariant: "danger",
        cancelTitle: "Cancel",
        cancelVariant: "outline-secondary",
        size: "sm",
        hideHeaderClose: false,
        centered: true,
      },
    },
    deleteUserFromOrgById: {
      //action from table content
      message: "Are you sure you want to delete ",
      config: {
        title: "Delete User from Organization",
        okTitle: "Confirm",
        okVariant: "danger",
        cancelTitle: "Cancel",
        cancelVariant: "outline-secondary",
        size: "sm",
        hideHeaderClose: false,
        centered: true,
      },
    },
    deleteServerById: {
      message: "Are you sure you want to delete ",
      config: {
        title: "Delete Server",
        okTitle: "Confirm",
        okVariant: "danger",
        cancelTitle: "Cancel",
        cancelVariant: "outline-secondary",
        size: "sm",
        hideHeaderClose: false,
        centered: true,
      },
    },
    editUserPassword: {
      //action from table content
      message: "If you confirm, a link to reset the password will be sent to your email.",
      config: {
        title: "Reset Password!",
        okTitle: "Confirm",
        okVariant: "success",
        cancelTitle: "Cancel",
        cancelVariant: "outline-danger",
        size: "sm",
        hideHeaderClose: false,
        centered: true,
      },
    },
    deletePlanById: {
      //action from table content
      message: "Are you sure you want to delete ",
      config: {
        title: "Delete Plan",
        okTitle: "Confirm",
        okVariant: "danger",
        cancelTitle: "Cancel",
        cancelVariant: "outline-secondary",
        size: "sm",
        hideHeaderClose: false,
        centered: true,
      },
    },
    deletePlanConfigById: {
      //action from table content
      message: "Are you sure you want to delete ",
      config: {
        title: "Delete Configuration",
        okTitle: "Confirm",
        okVariant: "danger",
        cancelTitle: "Cancel",
        cancelVariant: "outline-secondary",
        size: "md",
        hideHeaderClose: false,
        centered: true,
      },
    },
    togglePlanIsPrivate:{
      //action from table content
      message: "Change privacy status ",
      config: {
        title: "Plan Privacy",
        okTitle: "Confirm",
        okVariant: "danger",
        cancelTitle: "Cancel",
        cancelVariant: "outline-secondary",
        size: "md",
        hideHeaderClose: false,
        centered: true,
      },
    },
    togglePlanIsMainConfig:{
      //action from table content
      message: "Change main config",
      config: {
        title: "Plan Config Main",
        okTitle: "Confirm",
        okVariant: "danger",
        cancelTitle: "Cancel",
        cancelVariant: "outline-secondary",
        size: "md",
        hideHeaderClose: false,
        centered: true,
      },
    },
    clonePlanById:{
      //action from table content
      message: "Clone ",
      config: {
        title: "Clone Config",
        okTitle: "Confirm",
        okVariant: "danger",
        cancelTitle: "Cancel",
        cancelVariant: "outline-secondary",
        size: "md",
        hideHeaderClose: false,
        centered: true,
      },
    },
    editIsServerPublic:{
       //action from table content
       message: "Make server ",
       config: {
         title: "Edit server public status",
         okTitle: "Confirm",
         okVariant: "danger",
         cancelTitle: "Cancel",
         cancelVariant: "outline-secondary",
         size: "md",
         hideHeaderClose: false,
         centered: true,
       },
    },
    deleteOrgFromPlanById:{
      //action from table content
      message: "Make this plan invisible for ",
      config: {
        title: "Delete Organization from Plan Visibility",
        okTitle: "Confirm",
        okVariant: "danger",
        cancelTitle: "Cancel",
        cancelVariant: "outline-secondary",
        size: "md",
        hideHeaderClose: false,
        centered: true,
      },
    },
    deleteSession:{
      //action from table content
      message: "Are you sure you want to delete this session?",
      config: {
        title: "Delete User Session",
        okTitle: "Confirm",
        okVariant: "danger",
        cancelTitle: "Cancel",
        cancelVariant: "outline-secondary",
        size: "md",
        hideHeaderClose: false,
        centered: true,
      },
    },
    deleteIPById:{
      //action from table content
      message: "Are you sure you want to delete this IP?",
      config: {
        title: "Delete IP from whitelist",
        okTitle: "Confirm",
        okVariant: "danger",
        cancelTitle: "Cancel",
        cancelVariant: "outline-secondary",
        size: "md",
        hideHeaderClose: false,
        centered: true,
      },
    },
    embedUserSession:{
      //action from table content
      message: "Embed this User Session?",
      config: {
        title: "Incorporate Session",
        okTitle: "Confirm",
        okVariant: "primary",
        cancelTitle: "Cancel",
        cancelVariant: "outline-secondary",
        size: "md",
        hideHeaderClose: false,
        centered: true,
      },
    },
    //SERVICES
    deleteServiceById:{
      //action from table content
      message: "Are you sure you want to delete this service?",
      config: {
        title: "Delete Service",
        okTitle: "Confirm",
        okVariant: "danger",
        cancelTitle: "Cancel",
        cancelVariant: "outline-primary",
        size: "md",
        hideHeaderClose: false,
        centered: true,
      },
    },
    deleteServicePermission:{
      //action from table content
      message: "Are you sure you want to delete this permission",
      config: {
        title: "Delete Permission",
        okTitle: "Confirm",
        okVariant: "danger",
        cancelTitle: "Cancel",
        cancelVariant: "outline-primary",
        size: "md",
        hideHeaderClose: false,
        centered: true,
      },
    },
    //EVENTS
    deleteEventById:{
      //action from table content
      message: "Are you sure you want to delete this event?",
      config: {
        title: "Delete Event",
        okTitle: "Confirm",
        okVariant: "danger",
        cancelTitle: "Cancel",
        cancelVariant: "outline-primary",
        size: "md",
        hideHeaderClose: false,
        centered: true,
      },
    },
    changeServiceToken:{
      //action from table content
      message: "service.token.message",
      config: {
        title: "service.token.title",
        okTitle: "terms.confirmButton",
        okVariant: "primary",
        cancelTitle: "terms.cancelButton",
        cancelVariant: "outline-danger",
        size: "md",
        hideHeaderClose: false,
        centered: true,
      },
    },
    deleteEventPermission:{
      //action from table content
      message: "Are you sure you want to delete this permission",
      config: {
        title: "Delete Permission",
        okTitle: "Confirm",
        okVariant: "danger",
        cancelTitle: "Cancel",
        cancelVariant: "outline-primary",
        size: "md",
        hideHeaderClose: false,
        centered: true,
      },
    },
    revertCompiledScript:{
      //action from table content
      message: "compiled.table.revert.message",
      config: {
        title: "compiled.table.revert.title",
        okTitle: "terms.confirmButton",
        okVariant: "primary",
        cancelTitle: "terms.cancelButton",
        cancelVariant: "outline-danger",
        size: "md",
        hideHeaderClose: false,
        centered: true,
      },
    },
    deleteCompiledScript:{
      //action from table content
      message: "compiled.table.delete.message",
      config: {
        title: "compiled.table.delete.title",
        okTitle: "common.modal.ok",
        okVariant: "danger",
        cancelTitle: "common.modal.cancel",
        cancelVariant: "outline-secondary",
        size: "md",
        hideHeaderClose: false,
        centered: true,
      },
    },
    // WEBHOOK
    changeWebhookToken:{
      //action from table content
      message: "webhook.token.message",
      config: {
        title: "webhook.token.title",
        okTitle: "terms.confirmButton",
        okVariant: "primary",
        cancelTitle: "terms.cancelButton",
        cancelVariant: "outline-danger",
        size: "md",
        hideHeaderClose: false,
        centered: true,
      },
    },
    deleteWebhookById:{
      //action from table content
      message: "Are you sure you want to delete this webhook?",
      config: {
        title: "Delete Webhook",
        okTitle: "Confirm",
        okVariant: "primary",
        cancelTitle: "Cancel",
        cancelVariant: "outline-danger",
        size: "md",
        hideHeaderClose: false,
        centered: true,
      },
    },
    //INTEGRATOR
    deleteWebServiceById: {
      message: "Are you sure you want to delete this web service?",
      config: {
        title: "Delete Web Service",
        okTitle: "Confirm",
        okVariant: "primary",
        cancelTitle: "Cancel",
        cancelVariant: "outline-danger",
        size: "md",
        hideHeaderClose: false,
        centered: true,
      },
    }
  });
  
  