import axiosIns from "@/libs/axios";
import moment from "moment";
import { makeToast } from "@/layouts/components/Popups";
import i18n from '@/libs/i18n'
import {axiosCall} from '@/store/AxiosCaller.js'

export default {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getAllApps(_, payload) {

      return axiosCall({
        url: `/${payload.transmissionID}/transmission/third_party_app`,
        method: 'get',
        treatResponse: resp => resp
      })
      .then((resp)=>{
        let appData = [];

        resp.data.data.map((value, key) => {
          appData.push(value);
          appData[key].valid_until = moment(value.valid_until).format(
            "LLL"
          );
          appData[key].created_at = moment(value.created_at).format(
            "LLL"
          );
          appData[key].updated_at = moment(value.updated_at).format(
            "LLL"
          );
        });
        return appData;
      })
    },
    getAppById(_, payload) {
      return new Promise((resolve, reject) => {
        let transmission = payload.transmissionID

        let config = {
          headers: {
            "x-api-url": `/${transmission}/transmission/third_party_app/${payload.item}`,
            session: `Session ${localStorage.getItem("sessionKey")}`,
          },
        };

       console.trace(); axiosIns
          .get("/", config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {
              let appData = resp.data.data;

              appData.valid_until = moment(appData.valid_until).format("LLL");
              appData.created_at = moment(appData.created_at).format("LLL");
              appData.updated_at = moment(appData.updated_at).format("LLL");

              resolve(appData);
            } else {
              throw resp;
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error);
          });
      });
    },
    deleteAppById(_, payload) {
      return new Promise((resolve, reject) => {
        let transmission = payload.transmissionID
        
        let config = {
          headers: {
            "x-api-url": `/${transmission}/transmission/third_party_app/${payload.item}`,
            session: `Session ${localStorage.getItem("sessionKey")}`,
          },
        };

       console.trace(); axiosIns
          .delete("/", config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {
              resolve(resp);
            } else {
              throw resp;
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error);
          });
      });
    },
    getAuthExternalLink(_, payload) {
      return new Promise((resolve, reject) => {
        let transmission = payload.transmissionID
        let app_id = payload.enum_app
        
        let config = {
          headers: {
            "x-api-url": `/transmission/${transmission}/third_party_app/${app_id}/url-authentication`,
            session: `Session ${localStorage.getItem("sessionKey")}`,
          },
        };

        axiosIns
          .get("/", config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {
              resolve(resp);
            } else {
              throw resp;
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error);
          });
      });
    }
  }
};
