import { makeToast } from "@/layouts/components/Popups";
import i18n from '@/libs/i18n'

export function commonToast(namespace){
  
  const toastDictionary = {
    '403': {
      title: i18n.t('common.toast.forbidden.title'),
      text: i18n.t('common.toast.forbidden.message'),
      variant: "danger",
      icon: "SlashIcon",
    },
  }

  makeToast(toastDictionary[namespace])     
}

export function errorToast({title='Error!', text="Somethin went wrong"}){
  makeToast({
    title: title,
    text: text,
    variant: "danger",
    icon: "XIcon",
  })      
}

export function successToast({title='Success!' , text}){
  makeToast({
    title: title,
    text: text,
    variant: "success",
    icon: "CheckIcon",
  })      
}


export function copyText(data, text=""){
  copyToast(text)
  navigator.clipboard.writeText(data)
}

export function copyToast(text=""){
  makeToast({
    title: 'Copied to Clipboard',
    text: text,
    variant: "secondary",
    icon: "CopyIcon",
  })      
}
