export default [
  {
    path: '/integrator/organization/:orgID/services',
    name: 'integrator-org-service-view',
    component: () => import ('@/views/pages/integrator/OrganizationServices.vue'),
    meta: {
      action: "manage",
      title: "FiqOn - Integrator Organization View",
      pageTitle: "Integrator Organization View",
      breadcrumb: [
        {
					text: 'Organization',
					
				},
        {
					text: 'View Organization Services',
					active: true
				},
      ],
    },
  },
  {
    path: '/integrator/service',
    name: 'integrator-service',
    component: () => import ('@/views/pages/integrator/Integrator.vue'),
    meta: {
      action: "manage",
      title: "FiqOn - Integrator Services",
      pageTitle: "Integrator Services",
      breadcrumb: [
        {
					text: 'Services',
				  active: true,
				},
      ],
    },
  },
  {
    path: '/integrator/service/create',
    name: 'integrator-service-create',
    component: () => import ('@/views/pages/integrator/CreateService.vue'),
    meta: {
      action: "manage",
      title: "FiqOn - Create Integrator Service",
      pageTitle: "Integrator Service Create",
      breadcrumb: [
        {
					text: 'Services',
					to: { name: 'integrator-service' }
				},
        {
          text: 'Create Service',
          active: true,
        }
      ],
    },
  },
  {   
    path: '/integrator/service/:serviceID/edit',
    name: 'integrator-service-edit',
    component: () => import ('@/views/pages/integrator/EditService.vue'),
    meta: {
      action: "manage",
      title: "FiqOn - Integrator Services Edit",
      pageTitle: "Integrator Services Edit",
      breadcrumb: [
        {
					text: 'Services',
					to: { name: 'integrator-service' }
				},
        {
					text: 'Service Edit',
					active: true
				},
      ],
    },
  },
  {
    path: '/integrator/service/:serviceIdentifier/view',
    name: 'integrator-service-view',
    component: () => import ('@/views/pages/integrator/ServiceInfo.vue'),
    meta: {
      action: "manage",
      title: "FiqOn - Integrator Services View",
      pageTitle: "Integrator Services View",
      breadcrumb: [
        {
					text: 'Services',
					to: { name: 'integrator-service' }
				},
        {
					text: 'View Service',
					active: true
				},
      ],
    },
  },
]