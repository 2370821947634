export default [
	{
		path: '/connections:connectionID?',
		name: 'connections',
		component: () => import('@/views/pages/flux_connections/ViewConnections.vue'),
		meta: {
			title: "FiqOn - Transmission",
			pageTitle: 'Connections',
			breadcrumb: [
				{
					text: 'Connections',
					active: true,
				},
			],
		},
	},
]