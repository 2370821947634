
export default[
	{
		path: '/home',
		name: 'home',
		component: () => import('@/views/pages/Home.vue'),
		meta: {
			title: "FiqOn - Home",
			pageTitle: 'Home',
			breadcrumb: [
				{
					text: 'Home',
					active: true,
				},
			],
		},
	},
	{
		path: '/error-404',
		name: 'error-404',
		component: () => import('@/views/error/Error404.vue'),
		meta: {
			title: "FiqOn - 404 Not Found",
			layout: 'full',
		},
	},
	{
		path: '/politica-de-privacidade',
		name: 'politica-de-privacidade',
		component: () => import('@/views/pages/login/privacyPolicyModal.vue'),
		meta: {
			title: "FiqOn - Política de Privacidade",
			layout: 'full',
      resource: 'Auth',
		},
	},
	{
		path: '/privacy-policy',
		name: 'privacy-policy',
		component: () => import('@/views/pages/login/privacyPolicyModal.vue'),
		meta: {
			title: "FiqOn - Privacy Policy",
			layout: 'full',
		},
	},
	{
		path: '/not-authorized',
		name: 'not-authorized',
		component: () => import('@/views/error/NotAuthorized.vue'),
		meta: {
			title: "FiqOn - Not Authorized",
			layout: 'full',
		},
	},
]