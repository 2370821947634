import serviceModule from './service'
import requestModule from './request'
import releaseModule from './release'
import categoryModule from './category'
import webhookModule from './webhook'

export default {
  serviceModule,
  requestModule,
  releaseModule,
  categoryModule,
  webhookModule
}