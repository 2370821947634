export const warningMessages = Object.freeze({
  deleteServerById: {
    title: "Canceled! ",
    text: "Server not deleted.",
    variant: "warning",
    icon: "AlertTriangleIcon",
    solid: true,
  },
  deleteOrgById: {
    title: "Canceled! ",
    text: "Organization not deleted.",
    variant: "warning",
    icon: "AlertTriangleIcon",
    solid: true,
  },
  deleteUserFromOrgById: {
    title: "Canceled! ",
    text: "User not deleted from organization.",
    variant: "warning",
    icon: "AlertTriangleIcon",
    solid: true,
  },
});
