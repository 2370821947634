import toastMessages from './toastMessages'
import tableContent from './tableContent'
import modalContent from './modalContent'
import { enumServerType } from './enumServerType'

export default ({
  ...toastMessages,
  ...tableContent,
  ...modalContent,
  enumServerType,
})
