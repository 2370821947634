import { axiosCall } from "@/store/AxiosCaller";

export default {
  namespaced: true,
  state: {
    categories: null
  },
  getters: {
    getCategories: (state) => {
      return state.categories;
    }
  },
  mutations: {
    PUT_CATEGORIES(state , payload){
      const {data} = payload;
      state.categories = data      
    },
  },

  actions: {
    getAllCategories({ commit }, payload){
      return axiosCall({
        url: `/module/integrator_category`,
        method: 'get'
      })
      .then((resp)=>{
        commit('PUT_CATEGORIES', {data: resp})
        return resp
      })
    },
  }
};