import Vue from "vue";
import { axiosCall } from "../AxiosCaller";


export default {
  namespaced: true,
  state: {
    webhooksByTransmission: undefined,
    webhookInfo: undefined
  },
  getters: {
    getWebhooks: (state) => {
      return state.webhooksByTransmission
    },
    getWebhookInfo: (state) => {
      return state.webhookInfo
    }
  },
  mutations: {
    PUT_WEBHOOKS_BY_TRANSMISSION(state, payload){
      const {data} = payload;
      state.webhooksByTransmission = data.items;
    },
    PUT_WEBHOOK_INFO(state, payload){
      const {data} = payload;

      state.webhookInfo = data
      
      if ((!Array.isArray(state.webhooksByTransmission) || (!data))){
        return
      }
      const idx = state.webhooksByTransmission.findIndex(el => el.id == data.id)
      if (idx > -1){
        Vue.set(state.webhooksByTransmission, idx, data)
      }
      
    },
    INSERT_WEBHOOK_IN_TRANSMISSION(state, payload){
      const {data} = payload;

      if (!Array.isArray(state.webhooksByTransmission)) return
      
      state.webhooksByTransmission.push(data);
    },
    DELETE_WEBHOOK(state, payload){
      const {data} = payload;
      if (!Array.isArray(state.webhooksByTransmission)) return
    
      const idx = state.webhooksByTransmission.findIndex((el)=> el.id == data.webhookID);
      if (idx > -1){
        state.webhooksByTransmission.splice(idx, 1);
      }
    },
  },

  actions: {
    getWebhooksByTransmission({commit} , transmissionID){
      return axiosCall({
        url: `/${transmissionID}/transmission/webhook`,
        method: 'get',
      }).then((resp)=>{
        commit('PUT_WEBHOOKS_BY_TRANSMISSION', { data: resp})
      })
    },    
    insertWebhookInTransmission({commit}, {transmissionID, data}){
      return axiosCall({
        url: `/${transmissionID}/transmission/webhook`,
        method: 'post',
        payload: data
      }).then((resp)=>{
        commit('INSERT_WEBHOOK_IN_TRANSMISSION', { data: resp})
      })
    },
    getWebhookInfo({commit}, {transmissionID, webhookID}){
      return axiosCall({
        url: `/${transmissionID}/transmission/webhook/${webhookID}`,
        method: 'get',
      })
      .then((resp)=>{
        commit('PUT_WEBHOOK_INFO', { data: resp})
      })
    },
    patchWebhookIdentifier({commit}, {transmissionID, webhookID, data}){
      return axiosCall({
        url: `/${transmissionID}/transmission/webhook/${webhookID}/identifier`,
        method: 'patch',
        payload: data
      }).then((resp)=>{
        commit('PUT_WEBHOOK_INFO', { data: resp})
      })
    },    
    changeWebhookToken({commit}, {transmissionID, webhookID}){

      return axiosCall({
        url: `/${transmissionID}/transmission/webhook/${webhookID}/token`,
        method: 'patch',
        payload: {}
      }).then((resp)=>{
        commit('PUT_WEBHOOK_INFO', { data: resp})
      })
    },
    changeWebhookStatus({commit}, {transmissionID, webhookID, data}){
      return axiosCall({
        url: `/${transmissionID}/transmission/webhook/${webhookID}/enum_status`,
        method: 'patch',
        payload: data
      })
      .then((resp)=>{
        commit('PUT_WEBHOOK_INFO', { data: resp})
      })
    },
    deleteWebhook({commit}, {transmissionID, webhookID}){
      return axiosCall({
        url: `/${transmissionID}/transmission/webhook/${webhookID}`,
        method: 'delete'
      })
      .then((resp)=>{
        commit('PUT_WEBHOOK_INFO', { data: undefined}),
        commit('DELETE_WEBHOOK', {data: {transmissionID: transmissionID, webhookID: webhookID}})
      })
    },
    clearWebhhookInfo({commit}){
      commit('PUT_WEBHOOK_INFO', { data: undefined})
    }
  }
};