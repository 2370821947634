import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Vue from 'vue'
import 'animate.css'


// title(Toast string title)
// text(Toast string text)
// variant('primary', 'secondary', 'danger', 'success', 'warning', 'info', etc...)
// icon(Feather Icon Name)
// solid(true or false)

export const makeToast = (data)  => { 
    Vue.prototype.$toast({
        component: ToastificationContent,
        props: {
            title   : data.title,
            text    : data.text,
            variant : data.variant,
            icon    : data.icon,
            solid   : data.solid,
        },
    })
}

// title (Titulo do alert)
// customClass (Objeto com classes do alert) confirmButton: 'btn btn-primary'
// showClass (Animação de popup) popup: 'animate__animated animate__shakeX'
// buttonsStyling (botão estilizado? true or false)

export const makeSweetAlert = (title, confirmButton, popup, buttonsStyling) => {
    Vue.prototype.$swal({
        title,
        customClass: {
            confirmButton,
        },
        showClass: {
            popup
        },
        buttonsStyling,
    })
}