export default [
  {
    path: "/plans",
    name: "plans",
    component: () => import("@/views/pages/plan/Plans.vue"),
    meta: {
      action: "manage",
      title: "FiqOn - Plans",
      refreshTable: true,
      pageTitle: "Plan",
      breadcrumb: [
        {
          text: "Plan",
          active: true,
        },
      ],
    },
  },
  {
    path: "/plan/info-plan/:value",
    name: "info-plan",
    component: () => import("@/views/pages/plan/InfoPlan.vue"),
    meta: {
      action: "manage",
      title: "FiqOn - Plan Info",
      refreshInput: true,
      pageTitle: "Plan Info",
      breadcrumb: [
        {
          text: "Plan",
          to: { name: "plans" },
        },
        {
          text: "Plan Info",
          active: true,
        },
      ],
    },
  },
  {
    path: "/plan/info-plan/:planId/info-plan-config/:planConfigId",
    name: "info-plan-config",
    component: () => import("@/views/pages/plan/InfoPlanConfig.vue"),
    meta: {
      action: "manage",
      title: "FiqOn - Plan Config",
      refreshInput: true,
      pageTitle: "Plan Configuration Info",
      breadcrumb: [
        {
          text: "Plan",
          to: { name: "plans" },
        },
        {
          text: "Plan Info",
          active: false,
        },
        {
          text: "Plan Config Info",
          active: true,
        },
      ],
    },
  },
];