export default [
  {
    path: '/transmission/:transmissionID/integrate-apps',
    name: 'integrate-apps',
    component: () =>
        import ('@/views/pages/apps/IntegrateApps.vue'),
    meta: {
      action: "manage",
      title: "FiqOn - Integrate Apps",
      pageTitle: "Integrate Apps",
      breadcrumb: [
        {
          text: 'Transmission Dashboard',
          to: { name: 'transmission-dashboard' }
        },
        {
          text: "Apps",
          active: true,
        },
      ],
      loadTransmissionGroup: true,
			showTerminal: false,
    },
  },
]