export const infoMessages = Object.freeze({
  verify_email: {
    title: "Validate!", //title
    text: "Please verify your email.", //message
    variant: "info", //variant
    icon: "InboxIcon", //icon
    solid: true,
  },
  verify_phone: {
    title: "Validate!", //title
    text: "Please verify your phone.", //message
    variant: "info", //variant
    icon: "MessageSquareIcon", //icon
    solid: true,
  },
  reset_password_solicitation: {
    title: "Sent!",
    text: "A link to reset your password was sent to your email address 🔐",
    variant: "info",
    icon: "MailIcon",
    solid: true,
  },
  logout: {
    title: "See you soon! 👋",
    text: "Logged out successfully.",
    variant: "info",
    icon: "UserIcon",
    solid: true,
  },
  no_results: {
    title: "Is there a typo?",
    text: "Could not found any results!",
    variant: "info",
    icon: "SearchIcon",
    solid: true,
  }
  
});
