import { errorMessages } from './errorMessages'
import { infoMessages } from './infoMessages'
import { successMessages } from './successMessages'
import { warningMessages } from './warningMessages'

export default ({
    errorMessages,
    infoMessages,
    successMessages,
    warningMessages
})