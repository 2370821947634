import axiosIns from "@/libs/axios";
import moment from "moment";
import router from "@/router";
import { makeToast } from "@/layouts/components/Popups";
import i18n from '@/libs/i18n'
import { axiosCall } from "../AxiosCaller";

export default {
  state: {
    currentPlan: null,
    planAction: null,
    currentPlanConfig: null,
    planConfigAction: null,
    currentPlanConfigMetrics: null,
    planConfigMetricsAction: null,
    currentOrganizationPlan: null,
  },
  getters: {
    getCurrentPlan: (state) => {
      return state.currentPlan;
    },
    getPlanAction: (state) => {
      return state.planAction;
    },
    getCurrentPlanConfig: (state) => {
      return state.currentPlanConfig;
    },
    getPlanConfigAction: (state) => {
      return state.planConfigAction;
    },
    getCurrentPlanConfigMetrics: (state) => {
      return state.currentPlanConfigMetrics;
    },
    getPlanConfigMetricsAction: (state) => {
      return state.planConfigMetricsAction;
    },
    getCurrentOrganizationPlan: (state) => {
      return state.currentOrganizationPlan;
    }
  },
  mutations: {
    SET_CURRENT_PLAN(state, payload) {
      state.currentPlan = payload;
    },
    SET_PLAN_ACTION(state, payload) {
      state.planAction = payload;
    },
    SET_CURRENT_PLAN_CONFIG(state, payload) {
      state.currentPlanConfig = payload;
    },
    SET_PLAN_CONFIG_ACTION(state, payload) {
      state.planConfigAction = payload;
    },
    SET_CURRENT_PLAN_CONFIG_METRICS(state, payload) {
      state.currentPlanConfigMetrics = payload;
    },
    SET_PLAN_METRICS_ACTION(state, payload) {
      state.planConfigMetricsAction = payload;
    },
    SET_CURRENT_ORGANIZATION_PLAN(state, payload){
      state.currentOrganizationPlan = payload;
    }
  },
  actions: {
    ////////////  SETTERS //////////////////////
    setPlanAction({ commit }, state) {
      commit("SET_PLAN_ACTION", state);
    },
    setCurrentPlan({ commit }, payload) {
      commit("SET_CURRENT_PLAN", payload);
    },
    setPlanConfigAction({ commit }, state) {
      commit("SET_PLAN_CONFIG_ACTION", state);
    },
    setCurrentPlanConfig({ commit }, payload) {
      commit("SET_CURRENT_PLAN_CONFIG", payload);
    },
    setCurrentPlanConfigMetrics({ commit }, payload) {
      commit("SET_CURRENT_PLAN_CONFIG_METRICS", payload);
    },
    setPlanConfigMetricsAction({ commit }, state) {
      commit("SET_PLAN_METRICS_ACTION", state);
    },
    ////////////////////////////////////////////////////////////////////////////////

    async getAllPlans({ commit, dispatch }, data = null) {
      let config = {
        headers: {
          "x-api-url": await dispatch("resolveFilterUrl", {
            url: `/plan`,
            filters: data,
          }),
          session: `Session ${localStorage.getItem("sessionKey")}`,
        },
      };
      return new Promise((resolve, reject) => {
       console.trace(); axiosIns
          .get("/", config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {
              let plansInfo = [];
              let total_items = resp.data.data.paginator.total_items;

              resp.data.data.items.map(function(value, key) {
                plansInfo.push(value);
                plansInfo[key].category = value.enum_category.label;
                plansInfo[key].status = value.active ? "Active" : "Inactive";
                plansInfo[key].created_at = moment(value.created_at).format(
                  "LLL"
                );
                plansInfo[key].updated_at = moment(value.updated_at).format(
                  "LLL"
                );
              });

              plansInfo["totalItems"] = total_items;

              resolve(plansInfo);
            } else {
              throw resp
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error);
          });
      });
    },
    async getAllActivePlans({ commit, dispatch }, data = null) {
      commit("SET_IS_BTABLE_BUSY", true);
      let orgId = localStorage.getItem('selectedOrganization')
      let url = await dispatch("resolveUrl", {
        url: `/central/organization/${orgId}/plans`,
        params: data,
      })

      return axiosCall({
        url:  url,
        method: 'get',
      })
      .then((resp)=>{
        let plansInfo = [];

        resp.items.map(function(value, key) {
          plansInfo.push(value);
          plansInfo[key].category = value.enum_category.label;
          plansInfo[key].status = value.active ? "Active" : "Inactive";
          plansInfo[key].created_at = moment(value.created_at).format(
            "LLL"
          );
          plansInfo[key].updated_at = moment(value.updated_at).format(
            "LLL"
          );
          plansInfo[key].price = value.config.price.toLocaleString("pt-BR",{ style: "currency", currency: "BRL" });
          plansInfo[key].price_req_extra = value.config.price_req_extra.toLocaleString("pt-BR",{ style: "currency", currency: "BRL" });
        });

        return plansInfo;
      })

    },
    fetchPlanCategories(_) {
      return axiosCall({
        url: '/enum/plan/category',
        method: 'get',
      })
    },
    addNewPlan({ dispatch }, payload) {
      let postData = {
        data: {
          name: payload.data.newPlanName,
          enum_category: parseInt(payload.data.planCategory),
        },
      };
      let config = {
        headers: {
          "x-api-url": "/plan",
          session: `Session ${localStorage.getItem("sessionKey")}`,
        },
      };
      return new Promise((resolve, reject) => {
       console.trace(); axiosIns
          .post("/", postData, config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {

              if (data.newPlanConfig) {
                dispatch("setCurrentPlan", resp.data.data);
                dispatch("setModalObj", "planConfig");
              }

              resolve(resp.data);
            } else {
              throw resp
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error);
          });
      });
    },
    deletePlanById(_ , payload) {
      let config = {
        headers: {
          "x-api-url": `/plan/${payload.item.id}`,
          session: `Session ${localStorage.getItem("sessionKey")}`,
        },
      };
      return new Promise((resolve, reject) => {
       console.trace(); axiosIns
          .delete("/", config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {
              resolve(resp.data.data);
            } else {
              throw resp
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error);
          });
      });
    },
    setPlanConfig({ dispatch }, payload) {
      let planId = this.getters.getCurrentPlan.id;
      let config = {
        headers: {
          "x-api-url": "/plan/plan_config",
          session: `Session ${localStorage.getItem("sessionKey")}`,
        },
      };
      let postData = {
        data: {
          plan: planId,
          name: payload.data.configName,
          price: parseFloat(payload.data.planPrice),
          price_req_extra: parseFloat(payload.data.planExtraPrice),
          services_limit: parseInt(payload.data.servicesLimit),
          events_limit: parseInt(payload.data.eventsLimit),
          requests_limit: parseInt(payload.data.requestLimit),
        },
      };
      return new Promise((resolve, reject) => {
       console.trace(); axiosIns
          .post("/", postData, config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {
              dispatch("setActiveModal", false);

              resolve(resp);
            } else {
              throw resp
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error);
          });
      });
    },
    infoPlanById(_) {
      let plan = this.getters.getCurrentPlan.id;
      router.push({ name: "info-plan", params: { value: plan } });
    },
    getPlanById({ commit }, params) {
      let config = {
        headers: {
          "x-api-url": `/plan/${params.value}`,
          session: `Session ${localStorage.getItem("sessionKey")}`,
        },
      };
      return new Promise((resolve, reject) => {
       console.trace(); axiosIns
          .get("/", config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {

              let planInfo = []; //
              let value = resp.data.data; //

              planInfo = value;
              planInfo.category = value.enum_category.label;
              planInfo.status = value.active ? "Active" : "Inactive";
              planInfo.created_at = moment(value.created_at).format("LLL");
              planInfo.updated_at = moment(value.updated_at).format("LLL");

              resolve(planInfo);
            } else {
              throw resp
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error);
          });
      });
    },
    async getConfigsByPlanId({ commit, dispatch }, data = null) {
      let config = {
        headers: {
          // }/central/organization/by/name?value=string
          "x-api-url": await dispatch("resolveFilterUrl", {
            url: `/plan/plan_config/by/plan`,
            filters: data,
          }),
          session: `Session ${localStorage.getItem("sessionKey")}`,
        },
      };
      return new Promise((resolve, reject) => {
       console.trace(); axiosIns
          .get("/", config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {
              let planConfigInfo = [];
              let total_items = resp.data.data.paginator.total_items;

              // Orgs Info normzalized data //
              resp.data.data.items.map(function(value, key) {
                planConfigInfo.push(value);
                planConfigInfo[key].status = value.active
                  ? "Active"
                  : "Inactive";
                planConfigInfo[key].created_at = moment(
                  value.created_at
                ).format("LLL");
                planConfigInfo[key].updated_at = moment(
                  value.updated_at
                ).format("LLL");
                planConfigInfo[key].price = value.price.toLocaleString(
                  "pt-BR",
                  { style: "currency", currency: "BRL" }
                );
                planConfigInfo[
                  key
                ].price_req_extra = value.price_req_extra.toLocaleString(
                  "pt-BR",
                  { style: "currency", currency: "BRL" }
                );
              });

              planConfigInfo["totalItems"] = total_items;

              resolve(planConfigInfo);
            } else {
              throw resp
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error);
          });
      });
    },
    editPlan({ commit }, payload) {
      let planId = this.getters.getCurrentPlan.id;
      let action = this.getters.getPlanAction;
      let lowerAction = action.toLowerCase();
      
      let config = {
        headers: {
          "x-api-url": `/plan/${planId}/${lowerAction}`,
          session: `Session ${localStorage.getItem("sessionKey")}`,
        },
      };
      let postData = {
        data: {
          new_value: payload.data[`newPlan${action}`],
        },
      };
      return new Promise((resolve, reject) => {
       console.trace(); axiosIns
          .patch("/", postData, config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {
              
              resolve(resp);
            } else {
              throw resp
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error);
          });
      });
    },
    editIsPlanPrivate(_, data) {
      let planId = this.getters.getCurrentPlan.id;

      let config = {
        headers: {
          "x-api-url": `/plan/${planId}/is_private`,
          session: `Session ${localStorage.getItem("sessionKey")}`,
        },
      };
      let postData = {
        data: {
          new_value: data,
        },
      };
      return new Promise((resolve, reject) => {
       console.trace(); axiosIns
          .patch("/", postData, config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {
              resolve(resp);
            } else {
              throw resp
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error);
          });
      });
    },
    deletePlanConfigById(_, payload) {
      let config = {
        headers: {
          "x-api-url": `/plan/plan_config/${payload.item.id}`,
          session: `Session ${localStorage.getItem("sessionKey")}`,
        },
      };
      return new Promise((resolve, reject) => {
       console.trace(); axiosIns
          .delete("/", config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {
              resolve(resp.data.data);
            } else {
              throw resp
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error);
          });
      });
    },
    infoPlanConfigById(_) {
      let planId = this.getters.getCurrentPlan.id;
      let planConfigId = this.getters.getCurrentPlanConfig.id;

      router.push({
        name: "info-plan-config",
        params: { planId: planId, planConfigId: planConfigId },
      });
    },
    getPlanConfigById({ commit }, params) {
      let config = {
        headers: {
          "x-api-url": `/plan/plan_config/${params.planConfigId}`,
          session: `Session ${localStorage.getItem("sessionKey")}`,
        },
      };
      return new Promise((resolve, reject) => {
       console.trace(); axiosIns
          .get("/", config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {
              let planConfigInfo = []; //
              let value = resp.data.data; //

              planConfigInfo = value;

              planConfigInfo.active = value.active ? "Active" : "Inactive";
              planConfigInfo.created_at = moment(value.created_at).format(
                "LLL"
              );
              planConfigInfo.updated_at = moment(value.updated_at).format(
                "LLL"
              );

              planConfigInfo.metrics.map(function(_, key) {
                planConfigInfo.metrics[key].value = parseInt(
                  planConfigInfo.metrics[key].value
                ).toLocaleString("pt-BR");
              });

              resolve(planConfigInfo);
            } else {
              throw resp
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error);
          });
      });
    },
    togglePlanIsPrivate(_, payload) {
      let config = {
        headers: {
          "x-api-url": `/plan/${payload.id}/is_private`,
          session: `Session ${localStorage.getItem("sessionKey")}`,
        },
      };
      let postData = {
        data: {
          new_value: !payload.is_private,
        },
      };
      return new Promise((resolve, reject) => {
       console.trace(); axiosIns
          .patch("/", postData, config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {
              resolve(resp);
            } else {
              throw resp
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error);
          });
      });
    },
    togglePlanIsMainConfig(_, payload) {
      let planId = this.getters.getCurrentPlanConfig.id;

      let config = {
        headers: {
          "x-api-url": `/plan/plan_config/${planId}/main_config`,
          session: `Session ${localStorage.getItem("sessionKey")}`,
        },
      };
      let postData = {
        data: {
          new_value: payload.data,
        },
      };
      return new Promise((resolve, reject) => {
       console.trace(); axiosIns
          .patch("/", postData, config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {
              resolve(resp);
            } else {
              throw resp
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error);
          });
      });
    },
    editPlanConfig(_, payload) {
      let planConfigId = this.getters.getCurrentPlanConfig.id;
      let action = this.getters.getPlanConfigAction;
      let lowerAction = action.toLowerCase();
      
      let config = {
        headers: {
          "x-api-url": `/plan/plan_config/${planConfigId}/${lowerAction}`,
          session: `Session ${localStorage.getItem("sessionKey")}`,
        },
      };
      let postData = {
        data: {
          new_value: payload.data[`newPlanConfig${action}`],
        },
      };
      return new Promise((resolve, reject) => {
       console.trace(); axiosIns
          .patch("/", postData, config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {
              
              resolve(resp);
            } else {
              throw resp
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error);
          });
      });
    },
    editPlanConfigMetrics(_, payload) {
      let planConfigId = this.getters.getCurrentPlanConfig.id;
      let planConfigMetricId = this.getters.getCurrentPlanConfigMetrics;
      let action = this.getters.getPlanConfigMetricsAction;
      
      let config = {
        headers: {
          "x-api-url": `/plan/plan_config_metric/${planConfigMetricId}/${planConfigId}`,
          session: `Session ${localStorage.getItem("sessionKey")}`,
        },
      };
      let postData = {
        data: {
          value: payload.data[`newPlanConfigMetrics${action}`],
          
        },
      };
      return new Promise((resolve, reject) => {
       console.trace(); axiosIns
          .put("/", postData, config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {
              
              resolve(resp);
            } else {
              throw resp
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error);
          });
      });
    },
    clonePlanById(_, payload) {
      let planConfigId = payload.id
      let config = {
        headers: {
          "x-api-url": `/plan/plan_config/${planConfigId}/clone`,
          session: `Session ${localStorage.getItem("sessionKey")}`,
        },
      };
      let postData = {
        data: {},
      };
      return new Promise((resolve, reject) => {
       console.trace(); axiosIns
          .post("/", postData, config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {
              
              resolve(resp);
            } else {
              throw resp
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error);
          });
      });
    },
    getOrgsPlanVisibility({ commit }, params) {
      let config = {
        headers:{
          "x-api-url": `/plan/visibility/by/plan/${params.value}`,
          session: `Session ${localStorage.getItem("sessionKey")}`,
        },
      };
      return new Promise((resolve, reject) => {
       console.trace(); axiosIns
          .get("/", config)
          .then(async (resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {

              let orgsInfo = [];
              let total_items = resp.data.data.data.paginator.total_items;

              // Orgs Info normzalized data // 
              resp.data.data.data.items.map(function(value, key){
                orgsInfo.push(value.organization)
                orgsInfo[key].created_at = (moment(value.created_at).format('LLL'))
                orgsInfo[key].created_by = (value.organization.created_by.fullname)
              })

              orgsInfo['totalItems'] = await total_items

              resolve(orgsInfo);
            } else {
              throw resp
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error);
          });
      });
    },
    addOrganizationToPlanVisibility(_,  payload) {
      
      let planId = this.getters.getCurrentPlan.id
      let orgId = payload.data.org.id
      let postData = {
        data: {
          organization: orgId,
          plan: planId,
        },
      };
      let config = {
        headers: {
          "x-api-url": "/plan/visibility",
          session: `Session ${localStorage.getItem("sessionKey")}`,
        },
      };
      return new Promise((resolve, reject) => {
       console.trace(); axiosIns
          .post("/", postData, config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {
              resolve(resp.data);
            } else {
              throw resp
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error);
          });
      });
    },
    deleteOrgFromPlanById(_ , payload) {
      let planId = this.getters.getCurrentPlan.id
      
      let config = {
        headers: {
          "x-api-url": `/plan/visibility/${payload.data.id}/${planId}`,
          session: `Session ${localStorage.getItem("sessionKey")}`,
        },
      };
      return new Promise((resolve, reject) => {
       console.trace(); axiosIns
          .delete("/", config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {
              resolve(resp.data.data);
            } else {
              throw resp
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error);
          });
      });
    },
    setPlanSubscription(_ , data) {
      let postData = {
        central_transmission: data.data.id,
        plan_config: data.plan.config.id,
      }

      return axiosCall({
        url: "/plan/plan_subscription",
        method: "post",
        payload: postData,
        treatResponse: resp => resp.data
      })
    }
  },
};
