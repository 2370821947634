import store from "@/store"

export const successMessages = ({
  default: {
    title: "Working !",
    text: "",
    variant: "success",
    icon: "TopIcon",
    solid: true,
  },
  home: {
    title: "Logged In",
    text: "What are we doing today?",
    variant: "success",
    icon: "KeyIcon",
    solid: true,
  },
  login: {
    title: "Login Success!",
    text: "",
    variant: "success",
    icon: "CheckIcon",
    solid: true,
  },
  register: {
    title: "Succesfully Registered!",
    text: "Now, security thingys :)",
    variant: "success",
    icon: "SmileIcon",
    solid: true,
  },
  ip_verified: {
    title: "Your access location is now verified!",
    text: "Enter your access credentials to enter.",
    variant: "success",
    icon: "KeyIcon",
    solid: true,
  },
  email_verified: {
    title: "Email Verified!",
    text: "",
    variant: "success",
    icon: "MailIcon",
    solid: true,
  },
  phone_verified: {
    title: "Phone Verified!",
    text: "",
    variant: "success",
    icon: "SmartphoneIcon",
    solid: true,
  },
  password_reset:{
    title: "Password Redefined Successfuly!",
    text: "",
    variant: "success",
    icon: "KeyIcon",
    solid: true,
  },
  /////////////////////////////////////////////////////////
  addOrganization: {
    title: "Created!",
    text: "New organization added with success.",
    variant: "success",
    icon: "CheckIcon",
    solid: true,
  },
  deleteOrgById: {
    title: "Success!",
    text: "Organization deleted.",
    variant: "success",
    icon: "CheckIcon",
    solid: true,
  },
  deleteUserFromOrgById: {
    title: "Success!",
    text: "User deleted from Organization.",
    variant: "success",
    icon: "CheckIcon",
    solid: true,
  },
  editOrganizationUser: {
    title: "Success!",
    text: "User role updated.",
    variant: "success",
    icon: "CheckIcon",
    solid: true,
  },
  editOrganizationById: {
    title: "Success!",
    text: "Organization updated.",
    variant: "success",
    icon: "CheckIcon",
    solid: true,
  },
  addUserToOrganization: {
    title: "Success!",
    text: "User Added.",
    variant: "success",
    icon: "UserIcon",
    solid: true,
  },
  //SERVERS
  deleteServerById: {
    title: "Success!",
    text: "Server deleted.",
    variant: "success",
    icon: "CheckIcon",
    solid: true,
  },
  addServer: {
    title: "Success!",
    text: "Server Added.",
    variant: "success",
    icon: "PlusIcon",
    solid: true,
  },
  editServer: {
    title: "Success!",
    text: "Server Updated.",
    variant: "success",
    icon: "CheckIcon",
    solid: true,
  },
  updateServer: {
    title: "Success!",
    text: "Server Info Updated.",
    variant: "success",
    icon: "CheckIcon",
    solid: true,
  },
  isPublicEdit: {
    title: "Success!",
    text: "Server public status changed.",
    variant: "success",
    icon: "CheckIcon",
    solid: true,
  },
  addOrganizationToServer: {
    title: "Success!",
    text: "Organization Added to Current Server.",
    variant: "success",
    icon: "CheckIcon",
    solid: true,
  },
  //PROFILE
  editUser: {
    title: "Success!",
    text: "Info Updated",
    variant: "success",
    icon: "CheckIcon",
    solid: true,
  },
  deleteIPById: {
    title: "Success!",
    text: "Access Point Deleted",
    variant: "success",
    icon: "CheckIcon",
    solid: true,
  },
  deleteSession: {
    title: "Success!",
    text: "Session Deleted",
    variant: "success",
    icon: "CheckIcon",
    solid: true,
  },
  embedUserSession:{
    title: "Success!",
    text: `Your in control now 😈`,
    variant: "success",
    icon: "CheckIcon",
    solid: true,
  },
  //PLANS
  addNewPlan: {
    title: "Success!",
    text: "Plan added.",
    variant: "success",
    icon: "CheckIcon",
    solid: true,
  },
  deletePlanById: {
    title: "Success!",
    text: "Plan deleted.",
    variant: "success",
    icon: "CheckIcon",
    solid: true,
  },
  setPlanConfig:{
    title: "Success!",
    text: "New Configuration Added.",
    variant: "success",
    icon: "CheckIcon",
    solid: true,
  },  
  togglePlanIsMainConfig:{
    title: "Success!",
    text: "Main Config Updated",
    variant: "success",
    icon: "CheckIcon",
    solid: true,
  },
  editPlan:{
    title: "Success!",
    text: "Plan Updated",
    variant: "success",
    icon: "CheckIcon",
    solid: true,
  },
  editIsPlanPrivate:{
    title: "Success!",
    text: "Plan Privacy Updated",
    variant: "success",
    icon: "CheckIcon",
    solid: true,
  },
  togglePlanIsPrivate:{
    title: "Success!",
    text: "Plan Privacy updated",
    variant: "success",
    icon: "CheckIcon",
    solid: true,
  },
  deletePlanConfigById:{
    title: "Success!",
    text: "Plan Configuration Sucessfully Deleted",
    variant: "success",
    icon: "CheckIcon",
    solid: true,
  },
  clonePlanById:{
    title: "Success!",
    text: "Plan Configuration Clone Created! ",
    variant: "success",
    icon: "CheckIcon",
    solid: true,
  },
  deleteOrgFromPlanById:{
    title: "Success!",
    text: "Plan Visibility Deleted!",
    variant: "success",
    icon: "CheckIcon",
    solid: true,
  },
  editPlanConfigMetrics:{
    title: "Success!",
    text: "Plan Metrics Updated!",
    variant: "success",
    icon: "CheckIcon",
    solid: true,
  },
  editPlanConfig:{
    title: "Success!",
    text: "Plan Config Updated!",
    variant: "success",
    icon: "CheckIcon",
    solid: true,
  },
  // TRANSMISSION GROUP 
  planSelected:{
    title: "Plan Selected!",
    text: "",
    variant: "success",
    icon: "CheckIcon",
    solid: true,
  },
  baseServerSelected:{
    title: "Base Server Selected!",
    text: "",
    variant: "success",
    icon: "CheckIcon",
    solid: true,
  },
  systemLogServerSelected: {
    title: "System Log Server Selected!",
    text: "",
    variant: "success",
    icon: "CheckIcon",
    solid: true,
  },
  processedLogServerSelected:{
    title: "Processed Log Server Selected!",
    text: "",
    variant: "success",
    icon: "CheckIcon",
    solid: true,
  },
  createTransmissionGroup:{
    title: "Success!",
    text: "Transmission Created",
    variant: "success",
    icon: "CheckIcon",
    solid: true,
  },
  onCopy:{
    title: "Token Copied to Clipboard!",
    text: "",
    variant: "success",
    icon: "CheckIcon",
    solid: true,
  },
  //SERVICES
  addService:{
    title: "Success!",
    text: "Service Added",
    variant: "success",
    icon: "CheckIcon",
    solid: true,
  },
  deleteServiceById: {
    title: "Success!",
    text: "Service Deleted",
    variant: "success",
    icon: "CheckIcon",
    solid: true
  },
  editService: {
    title: "Success!",
    text: "Service Edited",
    variant: "success",
    icon: "CheckIcon",
    solid: true
  },
  addServicePermission: {
    title: "Success!",
    text: "Added Permission",
    variant: "success",
    icon: "CheckIcon",
    solid: true
  },
  deleteServicePermission: {
    title: "Success!",
    text: "Permission Deleted",
    variant: "success",
    icon: "CheckIcon",
    solid: true
  },

  //EVENTS
  addEvent:{
    title: "Success!",
    text: "Event Added",
    variant: "success",
    icon: "CheckIcon",
    solid: true,
  },
  editEvent:{
    title: "Success!",
    text: "Event Edited",
    variant: "success",
    icon: "CheckIcon",
    solid: true,
  },
  deleteEventById: {
    title: "Success!",
    text: "Event Deleted",
    variant: "success",
    icon: "CheckIcon",
    solid: true
  },
  addEventPermission: {
    title: "Success!",
    text: "Permission Added",
    variant: "success",
    icon: "CheckIcon",
    solid: true
  },
  detectObjectFieldsFromJSON: {
    title: "Success!",
    text: "JSON Added",
    variant: "success",
    icon: "CheckIcon",
    solid: true
  },
  deleteFullJSONObject: {
    title: "Success!",
    text: "JSON Deleted",
    variant: "success",
    icon: "CheckIcon",
    solid: true
  },
  canReceive: {
    title: "Success!",
    text: "Can Receive Successfully Changed",
    variant: "success",
    icon: "CheckIcon",
    solid: true
  },
  canSend: {
    title: "Success!",
    text: "Can Send Successfully Changed",
    variant: "success",
    icon: "CheckIcon",
    solid: true
  },


  //COMPILED
  changeCompiledActive: {
    title: "common.toast.success.title",
    text: "compiled.toast.change_active.success",
    variant: "success",
    icon: "CheckIcon",
    solid: true,
  },
  compile: {
    title: "common.toast.success.title",
    text: "compiled.toast.compile.success",
    variant: "success",
    icon: "CheckIcon",
    solid: true,
  },

  //WEBHOOK
  addServiceWebhook: {
    title: "Success!",
    text: "Webhook Added",
    variant: "success",
    icon: "CheckIcon",
    solid: true
  },
  deleteWebhookById: {
    title: "Success!",
    text: "Webhook deleted",
    variant: "success",
    icon: "CheckIcon",
    solid: true
  }
});
