export default class EnumReleaseTags {
  constructor() {
      this.items = [
          {
              "id": 1,
              "label": "enum.integrator.release_tag.latest",
              "variant": "light-info"
          },
          {
              "id": 2,
              "label": "enum.integrator.release_tag.dev",
              "variant": "light-warning"
          },
          {
              "id": 3,
              "label": "enum.integrator.release_tag.stable",
              "variant": "light-success"
          },
          {
              "id": 4,
              "label": "enum.integrator.release_tag.deprecated",
              "variant": "light-danger"
          },
      ];
  }
}