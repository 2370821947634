import { makeToast } from "@/layouts/components/Popups";
import custom from "@/custom"
import router from "@/router";

export default {
  state: {
    IsDebugMode: false
  },
  getters: {
    getIsDebugMode: state => {
      return state.IsDebugMode;
    }
  },
  mutations: {
    SET_IS_DEBUG_MODE(state, payload) {
      state.IsDebugMode = payload;
    }
  },
  actions: {
    isDebugMode({ commit }) {
      if(localStorage.getItem("mainSessionKey")){
        commit('SET_IS_DEBUG_MODE', true);
      }
      return
    },
    setDebugMode({ commit }, { session = null, state }) {
      if(state){
        localStorage.setItem("mainSessionKey", localStorage.getItem("sessionKey"));
        localStorage.setItem("sessionKey", session)
        // router.replace({ name: "login" }).then(() => {
          // makeToast(custom.successMessages.embedUserSession)
        // })
      }else{
        localStorage.setItem("sessionKey", localStorage.getItem("mainSessionKey"));
        localStorage.removeItem("mainSessionKey");
        // router.replace({ name: "profile" }).then(() => {
          //   makeToast(custom.successMessages.embedUserSession)
          // })
        }
        
      window.location.reload();
      commit('SET_IS_DEBUG_MODE', state);
      return
    }
  }
};